import React, { useState } from 'react';
import { withSnackbar } from 'notistack';
import mailgun from "mailgun-js";

const ContactUs = ({ enqueueSnackbar }) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setValues((prev) => ({
            ...prev, [name]: value
        }));
    }

    const handleSubmit = () => {
        const DOMAIN = 'donorsarmy.com';
        const mg = mailgun({ apiKey: process.env.REACT_APP_MAILGUN_API_KEY, domain: DOMAIN, host: 'api.eu.mailgun.net', });
        const msgToPerson = {
            to: values.email,
            from: 'DonorsArmy <support@donorsarmy.com>',
            subject: `Thank You for Reaching Out | Donor's Army`,
            text: `Hey ${values.name}, Thank you so much for reaching out to us.\nWe have a team of volunteers that has a limited capacity but we will try our best to get back to you ASAP.\n\nAverage Response Time is 6 Hours.\n\nThank You,\nHave an amazing day, ahead!\n\nTeam Donor's Army\n#SaveLives`,
        };
        mg.messages().send(msgToPerson, function (error, body) {
            if (error) {
            } else {
                enqueueSnackbar('Response received', { variant: 'success' })
            }
        });

        const msgToAdmin = {
            to: 'donorsarmy@gmail.com',
            from: 'DonorsArmy <support@donorsarmy.com>',
            subject: 'Contact Us Form Filled',
            text: `Name: ${values.name}\nEmail: ${values.email}\nMessage: ${values.message}`,
        };
        mg.messages().send(msgToAdmin, function (error, body) {
            if (error) {
            }
        });
    }

    return (
        <section className="text-gray-600 body-font mt-2 lg:mt-2 relative">
            <div className="container px-5 mx-auto flex flex-col md:flex-row items-center">


						{/*---------------------------------------1-----------------------------------*/}

					<div className="flex w-full lg:w-1/2  mb-2">
          <div className="h-full w-full flex flex-col lg:px-4">
          <div className="h-full">
          <h1 className="text-3xl lg:text-5xl mb-2 lg:mb-24 font-bold py-3" style={{
            fontFamily: `'Poppins', sans-serif`,
            // lineHeight:"44px",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            We would love to hear from you!!
            </h1>
          </div>

            <div className="w-full h-full" >
          <img src="/PagesImages/contactPageImage.png" className="h-full w-full" />
        </div>

        </div>
				
				</div>

								{/*---------------------------------------2-----------------------------------*/}
								
								<div className="lg:w-1/2 bg-primary mt-4 md:w-2/3 p-4 py-6 shadow-lg rounded-3xl mx-auto">
                    <div className="flex flex-wrap -m-2">
                        <div className="p-3 w-full">
                            <div className="relative">
                                <label htmlFor="name" className="leading-7 text-md text-white font-bold">Name<span style={{ color: "red" }}>*</span></label>
                                <input type="text" id="name" name="name" placeholder="Enter Your Name " className="w-full rounded-full border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" value={values.name} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="p-3 w-full">
                            <div className="relative">
                                <label htmlFor="email" className="leading-7text-md text-white font-bold">Email<span style={{ color: "red" }}>*</span></label>
                                <input type="email" id="email" name="email" placeholder="Enter Your Email" className="w-full rounded-full border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" value={values.email} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="p-3 w-full">
                            <div className="relative">
                                <label htmlFor="message" className="leading-7 text-md text-white font-bold">Message<span style={{ color: "red" }}>*</span></label>
                                <textarea id="message" name="message" placeholder="Go ahead! We are listening... " className="w-full  rounded-2xl border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" value={values.message} onChange={handleChange}></textarea>
                            </div>
                        </div>
                        <div className="p-2 w-full">
                            <button className="flex text-white justify-center w-full bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-lg" onClick={handleSubmit}>Submit</button>
                        </div>
                        <div className="p-2 w-full pt-8 mt-8 border-t border-white text-center">
                            <a href="mailto:donorsarmy@gmail.com" className="text-white">donorsarmy@gmail.com</a>
                            <br />
                            <span className="inline-flex mt-5">
                                <a target="_blank" href="https://www.facebook.com/Donors-Army-102409045328417" className="text-gray-500">
                                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                    </svg>
                                </a>
                                <a target="_blank" href="https://www.instagram.com/donorsarmy/" className="ml-4 text-gray-500">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                    </svg>
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/donors-army/" className="ml-4 text-gray-500">
                                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withSnackbar(ContactUs);