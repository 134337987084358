import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
export default function Header2({ user, signOut }) {
  const [show, setShow] = useState(false);
  let uid;
  if (user) {
    uid = user.uid;
  } else {
    uid = "abc";
  }
  return (
    <div>
      {/* Navigation starts */}
      {/* Mobile */}
      <div
        className={
          show
            ? "w-full h-full fixed z-40 transform  translate-x-0 "
            : "   w-full h-full fixed z-40 hidden transform -translate-x-full"
        }
      >
        <div
          className="bg-gray-800 opacity-50 inset-0 fixed w-full h-full"
          onClick={() => setShow(!show)}
        />
        <div className="w-64 z-20 absolute left-0  top-0 bg-white shadow flex-col justify-between transition duration-150 ease-in-out h-full">
          <div className="flex flex-col justify-between h-full">
            <div className="px-6 pt-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p className="text-bold md:text2xl text-base pl-3 text-gray-800">
                    Donors Army
                  </p>
                </div>
                <div
                  id="cross"
                  className=" text-gray-800"
                  onClick={() => setShow(!show)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-x"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1={18} y1={6} x2={6} y2={18} />
                    <line x1={6} y1={6} x2={18} y2={18} />
                  </svg>
                </div>
              </div>
              <ul className="f-m-m">
                <li className="text-white pt-8">
                  <NavLink
                    activeStyle={{
                      fontWeight: "bold",
                      color: "red"
                    }}
                    className="hover:text-gray-900"
                    onClick={() => setShow(!show)}
                    exact
                    to="/"
                  >
                    <div className="flex items-center">
                      <div className="md:w-6 md:h-6 w-5 h-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M7.16667 3H3.83333C3.3731 3 3 3.3731 3 3.83333V7.16667C3 7.6269 3.3731 8 3.83333 8H7.16667C7.6269 8 8 7.6269 8 7.16667V3.83333C8 3.3731 7.6269 3 7.16667 3Z"
                            stroke="#667EEA"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.16667 11.6667H3.83333C3.3731 11.6667 3 12.0398 3 12.5V15.8333C3 16.2936 3.3731 16.6667 3.83333 16.6667H7.16667C7.6269 16.6667 8 16.2936 8 15.8333V12.5C8 12.0398 7.6269 11.6667 7.16667 11.6667Z"
                            stroke="#667EEA"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.1667 11.6667H12.8333C12.3731 11.6667 12 12.0398 12 12.5V15.8334C12 16.2936 12.3731 16.6667 12.8333 16.6667H16.1667C16.6269 16.6667 17 16.2936 17 15.8334V12.5C17 12.0398 16.6269 11.6667 16.1667 11.6667Z"
                            stroke="#667EEA"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.1667 3H12.8333C12.3731 3 12 3.3731 12 3.83333V7.16667C12 7.6269 12.3731 8 12.8333 8H16.1667C16.6269 8 17 7.6269 17 7.16667V3.83333C17 3.3731 16.6269 3 16.1667 3Z"
                            stroke="#667EEA"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <p className="text-gray-700 ml-3 text-lg">Home</p>
                    </div>
                  </NavLink>
                </li>

                <li className="text-gray-700 pt-8">
                  <NavLink
                    activeStyle={{
                      fontWeight: "bold",
                      color: "red"
                    }}
                    to="/find-donor"
                    onClick={() => setShow(!show)}
                    className=" hover:text-gray-900"
                  >
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M2.33333 4.83333H4.83333C5.05435 4.83333 5.26631 4.74554 5.42259 4.58926C5.57887 4.43298 5.66667 4.22101 5.66667 4V3.16667C5.66667 2.72464 5.84226 2.30072 6.15482 1.98816C6.46738 1.67559 6.89131 1.5 7.33333 1.5C7.77536 1.5 8.19928 1.67559 8.51184 1.98816C8.8244 2.30072 9 2.72464 9 3.16667V4C9 4.22101 9.0878 4.43298 9.24408 4.58926C9.40036 4.74554 9.61232 4.83333 9.83333 4.83333H12.3333C12.5543 4.83333 12.7663 4.92113 12.9226 5.07741C13.0789 5.23369 13.1667 5.44565 13.1667 5.66667V8.16667C13.1667 8.38768 13.2545 8.59964 13.4107 8.75592C13.567 8.9122 13.779 9 14 9H14.8333C15.2754 9 15.6993 9.17559 16.0118 9.48816C16.3244 9.80072 16.5 10.2246 16.5 10.6667C16.5 11.1087 16.3244 11.5326 16.0118 11.8452C15.6993 12.1577 15.2754 12.3333 14.8333 12.3333H14C13.779 12.3333 13.567 12.4211 13.4107 12.5774C13.2545 12.7337 13.1667 12.9457 13.1667 13.1667V15.6667C13.1667 15.8877 13.0789 16.0996 12.9226 16.2559C12.7663 16.4122 12.5543 16.5 12.3333 16.5H9.83333C9.61232 16.5 9.40036 16.4122 9.24408 16.2559C9.0878 16.0996 9 15.8877 9 15.6667V14.8333C9 14.3913 8.8244 13.9674 8.51184 13.6548C8.19928 13.3423 7.77536 13.1667 7.33333 13.1667C6.89131 13.1667 6.46738 13.3423 6.15482 13.6548C5.84226 13.9674 5.66667 14.3913 5.66667 14.8333V15.6667C5.66667 15.8877 5.57887 16.0996 5.42259 16.2559C5.26631 16.4122 5.05435 16.5 4.83333 16.5H2.33333C2.11232 16.5 1.90036 16.4122 1.74408 16.2559C1.5878 16.0996 1.5 15.8877 1.5 15.6667V13.1667C1.5 12.9457 1.5878 12.7337 1.74408 12.5774C1.90036 12.4211 2.11232 12.3333 2.33333 12.3333H3.16667C3.60869 12.3333 4.03262 12.1577 4.34518 11.8452C4.65774 11.5326 4.83333 11.1087 4.83333 10.6667C4.83333 10.2246 4.65774 9.80072 4.34518 9.48816C4.03262 9.17559 3.60869 9 3.16667 9H2.33333C2.11232 9 1.90036 8.9122 1.74408 8.75592C1.5878 8.59964 1.5 8.38768 1.5 8.16667V5.66667C1.5 5.44565 1.5878 5.23369 1.74408 5.07741C1.90036 4.92113 2.11232 4.83333 2.33333 4.83333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p className="text-gray-700 ml-3 text-lg">Find Donor</p>
                      </div>
                    </div>
                  </NavLink>
                </li>

                <li className="text-gray-800 pt-8">
                  <NavLink
                    activeStyle={{
                      fontWeight: "bold",
                      color: "red"
                    }}
                    to="/volunteer"
                    onClick={() => setShow(!show)}
                    className="hover:text-gray-900"
                  >
                    <div className="flex items-center">
                      <div className="md:w-6 md:h-6 w-5 h-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6.66667 13.3333L8.33334 8.33334L13.3333 6.66667L11.6667 11.6667L6.66667 13.3333Z"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <p className="text-gray-800 ml-3 text-lg">Volunteer</p>
                    </div>
                  </NavLink>
                </li>

                <li className="text-gray-800 pt-8">
                  <NavLink
                    activeStyle={{
                      fontWeight: "bold",
                      color: "red"
                    }}
                    to="/contact"
                    onClick={() => setShow(!show)}
                    className="hover:text-gray-900"
                  >
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.83333 6.66667L2.5 10L5.83333 13.3333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.1667 6.66667L17.5 10L14.1667 13.3333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.6667 3.33333L8.33333 16.6667"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p className="text-gray-800 ml-3 text-lg">Contact Us</p>
                      </div>
                    </div>
                  </NavLink>
                </li>

                <li className="text-gray-800 pt-8">
                  <NavLink
                    activeStyle={{
                      fontWeight: "bold",
                      color: "red"
                    }}
                    to="/team"
                    onClick={() => setShow(!show)}
                    className="hover:text-gray-900"
                  >
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.83333 6.66667L2.5 10L5.83333 13.3333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.1667 6.66667L17.5 10L14.1667 13.3333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.6667 3.33333L8.33333 16.6667"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p className=" text-gray-800 ml-3 text-lg">Our Team</p>{" "}
                      </div>
                    </div>
                  </NavLink>
                </li>
                {!user && (<li className="text-gray-800 pt-8">
                  <NavLink
                    activeStyle={{
                      fontWeight: "bold",
                      color: "red"
                    }}
                    to="/login"
                    onClick={() => setShow(!show)}
                    className="hover:text-gray-900"
                  >
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.83333 6.66667L2.5 10L5.83333 13.3333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.1667 6.66667L17.5 10L14.1667 13.3333"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.6667 3.33333L8.33333 16.6667"
                              stroke="currentColor"
                              strokeWidth={1}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p className=" text-gray-800 ml-3 text-lg">Login</p>{" "}
                      </div>
                    </div>
                  </NavLink>
                </li>)}
                {user && (

                  <li className="text-gray-800 pt-8">
                    <NavLink
                      activeStyle={{
                        fontWeight: "bold",
                        color: "red"
                      }}
                      to={`/${uid}/userProfile`}
                      onClick={() => setShow(!show)}
                      className="hover:text-gray-900"
                    >
                      <div className="flex items-center">
                        <div className="flex items-center">
                          <div className="md:w-6 md:h-6 w-5 h-5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M5.83333 6.66667L2.5 10L5.83333 13.3333"
                                stroke="currentColor"
                                strokeWidth={1}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.1667 6.66667L17.5 10L14.1667 13.3333"
                                stroke="currentColor"
                                strokeWidth={1}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.6667 3.33333L8.33333 16.6667"
                                stroke="currentColor"
                                strokeWidth={1}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className=" text-gray-800 ml-3 text-lg">Profile</p>{" "}
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
                {user && (
                  <button
                    onClick={() => {
                      signOut();
                      setShow(!show);
                    }}
                    class="inline-flex items-center bg-gradient-to-t from-tertiary to-secondary border-0 pt-1 ml-8 pb-1.5 px-3 shadow-lg focus:shadow-sm focus:outline-none hover:bg-indigo-700 rounded text-white text-base mt-4 md:mt-0"
                  >
                    Logout
                <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      viewBox="0 0 24 24"
                      className="ml-1"
                    >
                      <path d="M17 19h4v2h-4v-2zm0-1h4v-2h-4v2zm0-3h4v-2h-4v2zm4-5v2h-5v10h5v2h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-7 2h-9v10h9v-10zm2-2v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4h8z" />
                    </svg>
                  </button>
                )}


              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Ends */}
      <nav className="w-full mx-auto bg-white shadow">
        <div className="container px-6 justify-between h-16 flex items-center lg:items-center mx-auto">
          <div className="h-full flex items-center">
            <div className="mr-10 flex items-center">
              <Link
                to="/"
                className="flex title-font font-medium items-center text-gray-900 md:mb-0"
              >
                <img
                  src="/hompageImages/Icon.png"
                  className="w-12 h-12 border-2 p-1 border-primary rounded-full"
                />
                <span className="ml-3 text-xl">
                  Donors<span className="text-primary">Army</span>
                </span>
              </Link>
            </div>
          </div>

          <div className="hidden lg:block">
            <NavLink
              activeClassName="active-navlink"
              className="mr-5 hover:text-gray-900"
              exact
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              activeClassName="active-navlink"
              to="/find-donor"
              className="mr-5 hover:text-gray-900"
            >
              Find Donor
            </NavLink>
            <NavLink
              activeClassName="active-navlink"
              to="/volunteer"
              className="mr-5 hover:text-gray-900"
            >
              Volunteer
            </NavLink>
            <NavLink
              activeClassName="active-navlink"
              to="/contact"
              className="mr-5 hover:text-gray-900"
            >
              Contact Us
            </NavLink>
            <NavLink
              activeClassName="active-navlink"
              to="/team"
              className="mr-5 hover:text-gray-900"
            >
              Our Team
            </NavLink>
            {user && (
              <NavLink
                activeClassName="active-navlink"
                to={`/${uid}/userProfile`}
                className="mr-5 hover:text-gray-900"
              >
                Profile
              </NavLink>
            )}
            {user && (
              <button
                onClick={signOut}
                class="inline-flex items-center bg-gradient-to-t from-tertiary to-secondary  border-0 pt-1 pb-1.5 px-3 shadow-lg focus:shadow-sm focus:outline-none hover:bg-indigo-700 rounded text-white text-base mt-4 md:mt-0"
              >
                Logout
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  viewBox="0 0 24 24"
                  className="ml-1"
                >
                  <path d="M17 19h4v2h-4v-2zm0-1h4v-2h-4v2zm0-3h4v-2h-4v2zm4-5v2h-5v10h5v2h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-7 2h-9v10h9v-10zm2-2v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4h8z" />
                </svg>
              </button>
            )}
            {!user && (
              <NavLink
                activeClassName="active-navlink"
                to="/login"
                className="mr-5 hover:text-gray-900"
              >
                Login
              </NavLink>
            )}
          </div>

          {/* burger/MENU/LIST icon starts */}
          <div className="visible lg:hidden flex items-center relative">
            <svg width="24" className="animate-bounce" height="24" aria-label="Main Menu"
              aria-haspopup="true" onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z" fill="#1040e2" /><path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" /></svg>
          </div>
        </div>
      </nav>
      {/* Navigation ends */}
    </div>
  );
}
