import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { bloodGroupList } from "../list/bloodGroupList";
import statesAndDistrictList from "../list/stateDistrictList";
import DatePicker from "./DatePicker";
import validator from "validator";
import { withSnackbar } from "notistack";
import firebase from "firebase";
import SimpleModal from "./SimpleModal";
import EligibilityModal from "./EligibilityModal";
import { Redirect, useHistory, useParams } from "react-router-dom";
import {
  eligibilityPlasmaModalBody,
  eligibilityBloodModalBody,
  termsAndCondition
} from "./ModalBody/ModalBody";
import Loader from "./Loader";

const RegisterDonor = ({ enqueueSnackbar, user }) => {
  const params = useParams();
  const history = useHistory();
  const defaultValues = {
    name: "",
    email: "",
    age: "",
    mobileNumber: "",
    state: "Select Your State",
    district: "Select Your District",
    pinCode: "",
    bloodGroup: "Select Your Blood Group",
    donor: false,
    dateOfCovidPositive: "",
    covidReport: false,
    termsConditions: true,
  };

  const defaultOTP = {
    otp: "",
  };

  const [otpValues, setOtpValues] = useState({ ...defaultOTP });
  const [t, setT] = useState({});
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [values, setValues] = useState({ ...defaultValues });
  const [indianStates, setIndianStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docID, setDocID] = useState(null);
  const [checkDonorStatus, setCheckDonorStatus] = useState(undefined);
  useEffect(() => {
    if (
      params.type.toString() === "plasma" ||
      params.type.toString() === "blood"
    ) {
      const stateArr = [defaultValues.state];
      statesAndDistrictList.forEach((state) => {
        stateArr.push(state.state);
      });
      setIndianStates(stateArr);

      db.collection("beneficiary")
        .where("mobileNumber", "==", user.phoneNumber.slice(3))
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setDocID(doc.id);
            setCheckDonorStatus(doc.data().donor);
            setValues({
              ...values,

              name: doc.data().name,
              email: doc.data().email,
              age: doc.data().age,
              mobileNumber: doc.data().mobileNumber,
              state: doc.data().state,
              district: doc.data().district,
              donor: doc.data().donor ? doc.data().donor : false,
              pinCode: doc.data().pinCode,
              bloodGroup: doc.data().bloodGroup,
            });
            // refreshDistricts(values.state);
          });
        });
    } else {
      history.push("/");
    }
  }, []);

  const { otp } = otpValues;
  let recaptchaVerifier;
  const sendOTP = () => {
    setLoading(true);
    recaptchaVerifier = window.recaptchaVerifier = new auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
    if (
      values.mobileNumber.length !== 10 ||
      !validator.isMobilePhone(values.mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    }
    const phoneNumber = `+91${values.mobileNumber}`;
    auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((e) => {
        document.getElementById("resend-otp").style.removeProperty("display");
        setTimeout(() => {
          document.getElementById("recaptcha-container").style = "display:none";
          document.getElementById("sendotp").style = "display:none";
          document.getElementById("mobilenumberblock").classList.add("flex");
          document
            .getElementById("mobilenumberblock")
            .classList.add("items-center");
        }, 500);
        setT(e);
        setLoading(false);
        enqueueSnackbar("OTP has been sent", {
          variant: "success",
          timeout: "2000",
        });
        setTimeout(() => {
          enqueueSnackbar("Now Verify Your OTP ", { variant: "warning" });
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Something Went Wrong ", { variant: "error" });
        document.getElementById("resend-otp").style.removeProperty("display");
        // document.getElementById("resend-otp").style="display:block";
      });
  };

  const reSend = () => {
    setLoading(true);
    document.getElementById("recaptcha-container-2").style = "display:block";
    recaptchaVerifier = window.recaptchaVerifier = new auth.RecaptchaVerifier(
      "recaptcha-container-2",
      { size: "normal" }
    );
    if (
      values.mobileNumber.length !== 10 ||
      !validator.isMobilePhone(values.mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    }
    const phoneNumber = `+91${values.mobileNumber}`;
    auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((e) => {
        setTimeout(() => {
          document.getElementById("recaptcha-container-2").innerHTML = "";
          document.getElementById("recaptcha-container-2").style =
            "display:none";
        }, 1000);
        setT(e);
        setLoading(false);
        enqueueSnackbar("OTP has been sent", {
          variant: "success",
          timeout: "2000"
        });
        setTimeout(() => {
          enqueueSnackbar("Now Verify Your OTP ", { variant: "warning" });
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Something Went Wrong ", { variant: "error" });
      });
  };

  const verify = () => {
    if (otp.length !== 6) {
      enqueueSnackbar("Invalid OTP", { variant: "error" });
      setOtpValues({ ...defaultOTP });
      return;
    }

    try {
      setLoading(true);
      t.confirm(otp)
        .then((result) => {
          // User signed in successfully.
          if (t.verificationId) {
            enqueueSnackbar(
              "You have successfully verified your mobile number",
              { variant: "success" }
            );
            document
              .getElementById("verifiedGreenTick")
              .style.removeProperty("display");
            document.getElementById("resend-otp").style = "display:none";
            document.getElementById("verify-otp").style = "display:none";
            document.getElementById("otp-input").style = "display:none";
          }
          setVerifyOTP(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(`${error.code.slice(5, 12)} OTP. Re-Enter the OTP`, {
            variant: "error"
          });
          setOtpValues({ ...defaultOTP });
        });
    } catch (e) {
      setLoading(false);
      if (e.message === "t.confirm is not a function") {
        enqueueSnackbar("Pls Send the OTP first", { variant: "error" });
        setOtpValues({ ...defaultOTP });
        return;
      }
    }
  };

  const refreshDistricts = (selectedState) => {
    const selectedStateObject = statesAndDistrictList.filter(
      (state) => state.state === selectedState
    );
    const districtArr = [defaultValues.district];

    selectedStateObject[0]?.districts?.forEach((district) => {
      districtArr.push(district);
    });

    setDistricts(districtArr);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const tempVal =
      name === "covidReport" || name === "termsConditions" || name === "donor"
        ? checked
        : value;
    if (name === "state") {
      refreshDistricts(value);
    }

    setValues((prev) => ({
      ...prev,
      [name]: tempVal
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Object.keys(values).forEach((key) => {
    //   values[key] = values[key].toString().trim();
    // });
    let {
      name,
      email,
      age,
      mobileNumber,
      state,
      district,
      pinCode,
      bloodGroup,
      dateOfCovidPositive,
      covidReport,
      donor,
      termsConditions
    } = values;

    if (validator.isEmpty(name)) {
      enqueueSnackbar("Name should not be empty", { variant: "error" });
      return;
    } else if (!validator.isEmail(email)) {
      enqueueSnackbar("Enter a valid Email address", { variant: "error" });
      return;
    } else if (!validator.isInt(age, { min: 0, max: 100 })) {
      age = parseInt(age, 10);
      enqueueSnackbar("Age should be valid", { variant: "error" });
      return;
    } else if (
      mobileNumber.length !== 10 ||
      !validator.isMobilePhone(mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    } else if (validator.isEmpty(state) || state === defaultValues.state) {
      enqueueSnackbar("Please select a state", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(district) ||
      district === defaultValues.district
    ) {
      enqueueSnackbar("Please select a district", { variant: "error" });
      return;
    } else if (!validator.isInt(pinCode, { min: 100000, max: 999999 })) {
      enqueueSnackbar("Please enter a valid Pin Code", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(bloodGroup) ||
      bloodGroup === defaultValues.bloodGroup
    ) {
      enqueueSnackbar("Please select a blood group", { variant: "error" });
      return;
    } else if (
      params.type === "plasma" &&
      !validator.isDate(dateOfCovidPositive, { format: "YYYY-MM-DD" })
    ) {
      enqueueSnackbar("Please select the date you were tested positive", {
        variant: "error"
      });
      return;
    } else if (
      params.type === "plasma" &&
      (!validator.isBoolean(covidReport) || covidReport === "false")
    ) {
      enqueueSnackbar("Please ensure that you have a positive report", {
        variant: "error"
      });
      return;
    }
    // else if (params.type === "blood" && donor === false) {
    //   enqueueSnackbar("Please fill CHECKBOX to Become Donor", {
    //     variant: "error",
    //   });
    //   return;
    // }
    else if (termsConditions === false) {
      enqueueSnackbar("Please Accept the Terms and Conditions", {
        variant: "error"
      });
      return;
    }
    // else if (verifyOTP == false) {
    //   enqueueSnackbar("Invalid OTP", { variant: "error" });
    //   return;
    // }
    setLoading(true);
    db.collection("beneficiary")
      .where("mobileNumber", "==", mobileNumber)
      .where("visible", "==", true)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0) {
          querySnapshot.forEach((doc) => {
            if (false) {
              setLoading(false);
              return enqueueSnackbar(
                "This mobile number is already registered",
                {
                  variant: "warning"
                }
              );
            } else {
              setLoading(false);
              return db
                .collection("beneficiary")
                .doc(docID)
                .update({
                  donor: donor
                })
                .then(() => {
                  document.getElementById("alert").innerText =
                    "Your Status Updated!!";
                  setTimeout(() => {
                    document.getElementById("alert").innerText = "";
                  }, 4000);

                  db.collection("beneficiary")
                    .doc(docID)
                    .get()
                    .then((doc) => {
                      setCheckDonorStatus(doc.data().donor);
                    });

                  document
                    .getElementById("registration_status")
                    .scrollIntoView({ behavior: "smooth" });

                  setValues({
                    ...defaultValues
                  });
                  setLoading(false);
                  enqueueSnackbar("Thank you for your contribution", {
                    variant: "success"
                  });
                });
              // return enqueueSnackbar("This mobile number is already registered", {
              //   variant: "warning",
              // });
              // } else {
              //   const obj = {
              //     // name,
              //     // email,
              //     // age,
              //     // mobileNumber,
              //     // state,
              //     // district,
              //     // pinCode,
              //     donor,
              //     // bloodGroup,
              //     // visible: true,
              //     // createdAt: firebase.firestore.Timestamp.now(),
              //   };

              //   // if (params.type === "plasma") {
              //   //   obj.dateOfCovidPositive = dateOfCovidPositive;
              //   //   obj.covidReport = covidReport;
              //   // }

              // }
            }
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Some error occurred", { variant: "error" });
      });
  };

  return (
    <div style={{ position: "relative" }}>
      {/* {params.type === "plasma" && (
        <EligibilityModal body={[eligibilityPlasmaModalBody]} />
      )} */}

      {params.type === "blood" && (
        <EligibilityModal body={[eligibilityBloodModalBody]} />
      )}
      {loading && <Loader />}

      <section className="text-gray-600 body-font py-5">
        <div className="container px-5 py-5 mx-auto flex flex-col items-center">
          <div>
            <section className="text-gray-600 body-font">
              <div className="container px-5 py-8 mx-auto w-screen">
                <div className="flex flex-col text-center w-full">
                  <h1
                    id="registration_status"
                    className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
                  >
                    Help people in Need!
                  </h1>

                  <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                    {params.type === "plasma"
                      ? "Your Plasma Can Save Someone`s Life"
                      : "Your Blood Can Save Someone`s Life"}
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="dfd bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
            <p
              id="alert"
              className="text-red-500 font-bold text-center mb-1"
            ></p>
            <h2 className="text-gray-900 text-lg font-medium title-font mb-2">
              {params.type === "plasma"
                ? "Registration Form For Plasma Donation"
                : "Registration Form For Blood Donation"}
            </h2>

            {checkDonorStatus ? (
              <span>
                Status:{" "}
                <span className="text-green-500 font-bold">
                  Registered
                  <span class="ml-2 h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-green-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                  </span>
                </span>
              </span>
            ) : (
              <span>
                Status:{" "}
                <span className="text-red-500 font-bold">
                  Unregistered
                  <span class="ml-2 h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-red-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                  </span>
                </span>
              </span>
            )}
            {params.type === "plasma" && (
              <p className="mb-4">
                <b> Only For Covid Recovered Patient</b>
              </p>
            )}

            <div className="relative mb-4 mt-2">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                type="text"
                id="name"
                name="name"
                value={values.name}
                // onChange={handleChange}
                placeholder="Enter Your Name"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                type="email"
                id="email"
                name="email"
                value={values.email}
                // onChange={handleChange}
                placeholder="Enter Your Email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="age" className="leading-7 text-sm text-gray-600">
                Age<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                type="number"
                id="age"
                name="age"
                value={values.age}
                // onChange={handleChange}
                placeholder="Enter Your Age"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div
              id="mobilenumberblock"
              className=" lg:flex lg:flex-row lg:items-center   "
            >
              <div className="relative mb-4   lg:w-full">
                <label
                  htmlFor="mobileNumber"
                  className="leading-7 text-sm text-gray-600"
                >
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  disabled
                  type="number"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  placeholder="Enter Your Mobile Number"
                  // onChange={handleChange}
                  className="w-full md:w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              {/* <div
                id="verifiedGreenTick"
                className="w-5"
                // style={{ display: "none" }}
              >
                <i
                  className="fas fa-check-circle"
                  style={{ color: "green" }}
                ></i>
              </div> */}

              {/* <div className="lg:w-2/6 ">
                <button
                  type="submit"
                  id="sendotp"
                  onClick={sendOTP}
                  className=" mt-1.5 -mb-1 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-sm"
                >
                  Send OTP
                </button>
              </div> */}
            </div>

            {/* <div id="recaptcha-container"></div> */}

            {/* <div
              id="otp-input"
              className="lg:flex lg:flex-row lg:items-center mt-2"
            >
              <div className="relative mb-4 lg:w-3/6">
                <label
                  htmlFor="otp"
                  className="leading-7 text-sm text-gray-600"
                >
                  OTP<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="otp"
                  name="otp"
                  value={otpValues.otp}
                  placeholder="Please Enter rececived OTP"
                  onChange={(e) => {
                    setOtpValues({ ...defaultOTP, otp: e.target.value });
                  }}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="lg:w-3/6 ml-1 mt-1">
                <button
                  type="submit"
                  onClick={verify}
                  id="verify-otp"
                  className=" mt-2 text-white bg-green-500 border-0 py-2 px-10 focus:outline-none hover:bg-indigo-600 rounded text-sm"
                >
                  Verify
                </button>
                <button
                  type="button"
                  onClick={reSend}
                  id="resend-otp"
                  style={{ display: "none" }}
                  className="mt-1 mb-2 lg:mx-1 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-sm"
                >
                  Resend OTP
                </button>
                <div id="recaptcha-container-2"></div>
              </div>
            </div> */}

            <div className="relative mb-4">
              <label
                htmlFor="state"
                className="leading-7 text-sm text-gray-600"
              >
                State<span style={{ color: "red" }}>*</span>
              </label>
              <select
                disabled
                name="state"
                value={values.state}
                // onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                {indianStates.map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="district"
                className="leading-7 text-sm text-gray-600"
              >
                District<span style={{ color: "red" }}>*</span>
              </label>
              <select
                disabled
                name="district"
                value={values.district}
                // onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                {<option key={values.district}>{values.district}</option>}
              </select>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="pinCode"
                className="leading-7 text-sm text-gray-600"
              >
                Pin Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                type="number"
                id="pinCode"
                name="pinCode"
                value={values.pinCode}
                placeholder="Enter Your Pin Code"
                // onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            <div className="relative mb-4">
              <label
                htmlFor="bloodGroup"
                className="leading-7 text-sm text-gray-600"
              >
                Blood Group<span style={{ color: "red" }}>*</span>
              </label>
              <select
                disabled
                name="bloodGroup"
                value={values.bloodGroup}
                // onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value={defaultValues.bloodGroup}>
                  {defaultValues.bloodGroup}
                </option>
                {bloodGroupList.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            {params.type === "plasma" && (
              <div className="relative mb-4">
                <label
                  htmlFor="dateOfCovidPositive"
                  className="leading-7 text-sm text-gray-600"
                >
                  Date on which you were Recovered From Covid-19
                  <span style={{ color: "red" }}>*</span>
                </label>
                {
                  <DatePicker
                    setDateValue={handleChange}
                    value={values.dateOfCovidPositive}
                    name="dateOfCovidPositive"
                  />
                }
              </div>
            )}
            {params.type === "plasma" && (
              <div className="relative mb-4">
                <input
                  type="checkbox"
                  id="covidReport"
                  name="covidReport"
                  value={values.covidReport}
                  onChange={handleChange}
                  className="mt-3"
                />
                <label
                  htmlFor="covidReport"
                  style={{ display: "inline-block" }}
                  className="ml-2 leading-7 text-sm text-gray-600"
                >
                  Confirm that you have your Covid Positive Report.
                </label>
              </div>
            )}
            <div className="relative mb-4">
              <input
                disabled
                type="checkbox"
                id="termsConditions"
                name="termsConditions"
                checked={values.termsConditions}
                value={values.termsConditions}
                onChange={handleChange}
                className="mt-3"
              />
              <label
                htmlFor="termsConditions"
                className="ml-2 leading-7 text-sm text-gray-600"
              >
                <span className="mr-1">I agree to the</span>
                <SimpleModal
                  frontText="Terms and Conditions"
                  body={[termsAndCondition]}
                />
              </label>
            </div>
            <div className="relative mb-4">
              <input
                // disabled={checkDonorStatus}
                type="checkbox"
                id="donor"
                name="donor"
                checked={values.donor}
                value={values.donor}
                onChange={handleChange}
                className="mt-3"
              />
              <label
                htmlFor="donor"
                style={{ display: "inline-block" }}
                className="ml-2 leading-7 text-sm text-gray-600"
              >
                Update my status
              </label>
            </div>
            <button
              // disabled={checkDonorStatus}
              hidden={checkDonorStatus && values.donor}
              type="submit"
              onClick={onSubmit}
              className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Submit
            </button>

            <p className="mt-2 ">
              * Your Information will be available in public domain until you
              unregister <span className="lg:ml-0  ml-1"> yourself.</span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withSnackbar(RegisterDonor);
