import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css"; 
import "./assets/main.css";
import RegisterDonor from "./component/RegisterDonor";
import RemoveDonor from "./component/RemoveDonor";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Team from "./component/Team";
import TopSection from "./component/TopSection";
import React, { useEffect, useState } from "react";
import ContactUs from "./component/ContactUs";
import VolunteerForm from "./component/VolunteerForm";
import Blog from "./component/Blog";
import ReadBlog from "./component/ReadBlog";
import Donate from "./component/Donate";
import Login from "./component/Login";
import firebase from "firebase";
import FindDonor from "./component/FindDonor";
import SignUp from "./component/SignUp";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import UserProfile from "./component/UserProfile";
import Header2 from "./component/Header2";
function App() {
  // const [{user}, dispatch] = useStateValue();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  setInterval(() => {
    if (localStorage.getItem("user") === null) {
      setUser(null);
    }
  }, 2000);

  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      setUser(null);
    }
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  const signOut = () => {
    auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("user");
        setUser(null);
        <Redirect to="/login" />;
      });
  };
  return (
    <div className="App">
      {/* {loading && <Loader/>} */}
      <Router>
        <Header2 user={user} signOut={signOut} />
        <div className="main">
          <Switch>
            <Route exact path="/">
              <TopSection />
            </Route>
            <Route exact path="/login">
              {!user ? (
                <Login setUser={setUser} />
              ) : (
                <Redirect to={`/${user.uid}/userProfile`} />
              )}
            </Route>
            <Route exact path="/signUp">
              {!user ? (
                <SignUp setUser={setUser} />
              ) : (
                <Redirect to={`/${user.uid}/userProfile`} />
              )}
            </Route>
            <Route exact path="/find-donor">
              {user ? <FindDonor /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/remove-donor">
                {user ? <RemoveDonor user={user}/> : <Redirect to="/login"/>}
            </Route>
            <Route exact path="/donate/:type">
              {user ? <UserProfile user={user} /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/team">
              <Team />
            </Route>
            <Route exact path="/contact">
              <ContactUs />
            </Route>
            <Route exact path="/volunteer">
              <VolunteerForm />
            </Route>
            {/* <Route exact path="/blog">
              <Blog />
            </Route> */}
            {/*<Route exact path="/readBlog/:slug">*/}
            {/*  <ReadBlog />*/}
            {/*</Route>*/}
            <Route exact path="/:uid/userProfile">
              {user ? <UserProfile user={user}/> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
