import React from "react";
import { Link, NavLink } from "react-router-dom";

function Header({ user, signOut }) {
  let uid;
  if (user) {
    uid = user.uid;
  } else {
    uid = "abc";
  }

  return (
    <div>
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link
            to="/"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <img
              src="/android-chrome-512x512.png"
              className="w-12 h-12 text-white p-1 bg-indigo-500 rounded-full"
              viewBox="0 0 24 24"
            ></img>
            <span className="ml-3 text-xl">
              Donors<span style={{ color: "red" }}>Army</span>
            </span>
          </Link>
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <NavLink
              activeClassName="active-navlink"
              className="mr-5 hover:text-gray-900"
              exact
              to="/"
            >
              Home
            </NavLink>
            
            {/*<NavLink*/}
            {/*  activeClassName="active-navlink"*/}
            {/*  to="/donate/blood"*/}
            {/*  className="mr-5 hover:text-gray-900"*/}
            {/*>*/}
            {/*  Donate*/}
            {/*</NavLink>*/}
            <NavLink
              activeClassName="active-navlink"
              to="/find-donor"
              className="mr-5 hover:text-gray-900"
            >
              Find Donor
            </NavLink>
            {/* <NavLink
              activeClassName="active-navlink"
              to="/blog"
              className="mr-5 hover:text-gray-900"
            >
              Blog
            </NavLink> */}
            <NavLink
              activeClassName="active-navlink"
              to="/volunteer"
              className="mr-5 hover:text-gray-900"
            >
              Volunteer
            </NavLink>
            <NavLink
              activeClassName="active-navlink"
              to="/contact"
              className="mr-5 hover:text-gray-900"
            >
              Contact Us
            </NavLink>
            <NavLink
              activeClassName="active-navlink"
              to="/team"
              className="mr-5 hover:text-gray-900"
            >
             Our  Team
            </NavLink>
            {user && (
              <NavLink
                activeClassName="active-navlink"
                to={`/${uid}/userProfile`}
                className="mr-5 hover:text-gray-900"
              >
                Profile
              </NavLink>
            )}
          </nav>
          {user && (
            <button
              onClick={signOut}
              class="inline-flex items-center bg-indigo-500 border-0 pt-1 pb-1.5 px-3 shadow-lg focus:shadow-sm focus:outline-none hover:bg-indigo-700 rounded text-white text-base mt-4 md:mt-0"
            >
              Logout
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="white"
                viewBox="0 0 24 24"
                className="ml-1"
              >
                <path d="M17 19h4v2h-4v-2zm0-1h4v-2h-4v2zm0-3h4v-2h-4v2zm4-5v2h-5v10h5v2h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-7 2h-9v10h9v-10zm2-2v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4h8z" />
              </svg>
            </button>
          )}
          {!user && <NavLink
              activeClassName="active-navlink"
              to="/login"
              className="mr-5 hover:text-gray-900"
            >
              Login
            </NavLink>}
        </div>
      </header>
    </div>
  );
}

export default Header;
