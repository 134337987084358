import React from "react";
import { team } from "../list/team";
import { special } from "../list/specialThanks";

function Team() {
  return (
    <>
      {/* <section class="text-gray-600 body-font">
        <div class="container px-5 pt-8 pb-4 mx-auto">
          <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
            <h1 className="text-2xl font-medium title-font mb-4 text-gray-900 tracking-widest">
              Vision
            </h1>
            <p class="leading-relaxed text-lg">
              Accomplishing it's mission through development of a critical mass
              of outstanding and dedicated individuals either directly or
              through strategic collaborators. To motivate and train the next
              generation youth leaders to ensure sustainability of the mission
              of voluntary donation.
            </p>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-6 mx-auto">
          <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
            <h1 className="text-2xl font-medium title-font mb-4 text-gray-900 tracking-widest">
              Mission
            </h1>
            <p class="leading-relaxed text-lg">
              Our main aim is to pave way for a better and safer tomorrow by
              providing a transparent platform for blood donation and reach the
              verified donors by displaying real time DATA.
            </p>
          </div>
        </div>
      </section> */}
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-16 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="text-2xl font-medium title-font mb-4 text-gray-900 tracking-widest">
              OUR TEAM
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Our Team Members are fully committed to you to get blood as soon
              as possible as your health is our mission.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2  -m-4">
            {team.map((member, index) => {
              return (
                <div key={index} className="p-4 lg:w-full">
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img
                      alt="team"
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={member.image}
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-lg text-gray-900">
                        {member.name}
                      </h2>
                      <h3 className="text-gray-500 mb-3">{member.role}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/*<div className="flex flex-col text-center w-full mb-20 mt-20">*/}
          {/*  <h1 className="text-2xl font-medium title-font text-gray-900 tracking-widest">SPECIAL THANKS</h1>*/}
          {/*  /!* <p className="lg:w-2/3 mx-auto leading-relaxed text-base"></p> *!/*/}
          {/*</div>*/}
          {/*<div className="grid lg:grid-cols-2 md:grid-cols-2  -m-4">*/}
          {/*  {special.map(member => {*/}
          {/*    return (*/}
          {/*      < div key={member.name} className="p-4 lg:w-full" >*/}
          {/*        <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">*/}
          {/*          <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" src={member.image} />*/}
          {/*          <div className="flex-grow sm:pl-8">*/}
          {/*            <h2 className="title-font font-medium text-lg text-gray-900">{member.name}</h2>*/}
          {/*            <h3 className="text-gray-500 mb-3">{member.role}</h3>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>
      </section>
    </>
  );
}

export default Team;
