import React, {useState} from "react";
import {db} from "../firebase";

import validator from "validator";
import {withSnackbar} from "notistack";
import MyRegistrations from "./MyRegistrations";

const DonorListForm = ({enqueueSnackbar, user}) => {
    const defaultValues = {
        email: "",
        mobileNumber: "",
        checkbox: false,
    };

    const [values, setValues] = useState({...defaultValues});
    const [myReg, setMyReg] = useState([]);

    const handleChange = (e) => {
        const {name, value, checked} = e.target;
        const tempVal = name === "checkbox" ? checked : value;

        setValues((prev) => ({
            ...prev,
            [name]: tempVal,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        Object.keys(values).forEach((key) => {
            values[key] = values[key].toString().trim();
        });

        let {email, mobileNumber, checkbox} = values;

        if (!validator.isEmail(email) || user.email !== email) {
            enqueueSnackbar("Enter a valid Email address", {variant: "error"});
            return;
        } else if (
            mobileNumber.length !== 10 ||
            !validator.isMobilePhone(mobileNumber, "en-IN")
        ) {
            enqueueSnackbar(
                "Enter valid 10 digit mobile number without country code",
                {variant: "error"}
            );
            return;
        } else if (user.phoneNumber !== ("+91" + mobileNumber)) {
            enqueueSnackbar("Please enter a valid mobile number", {
                variant: "error",
            });
            return;
        } else if (!checkbox) {
            enqueueSnackbar("Please ensure that you have a positive report", {
                variant: "error",
            });
            return;
        }

        db.collection("beneficiary")
            .where("email", "==", email)
            .where("mobileNumber", "==", mobileNumber)
            .where("visible", "==", true)
            .get()
            .then((querySnapshot) => {
                const arr = [];
                querySnapshot.forEach((doc) => {
                    arr.push({
                        ...doc.data(),
                        bloodGroup: doc.data().bloodGroup.split("(")[0].trim(),
                        id: doc.id,
                    });
                });
                setMyReg(arr);
            })
            .catch((err) =>
                enqueueSnackbar("Error fetching data", {variant: "error"})
            );

        setValues({
            ...defaultValues,
        });
    };

    const removePersonFromList = (person) => {
        const people = myReg.filter((reg) => reg.id !== person.id);

        if (people.length === 0) {
            enqueueSnackbar("You have no active registrations", {variant: "info"});
        }

        setMyReg(people);
    };

    return myReg.length > 0 ? (
        <MyRegistrations
            people={myReg}
            removePersonFromList={removePersonFromList}
        />
    ) : (
        <div>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-5 mx-auto -mt-4 flex flex-wrap items-center justify-center">
                    <div>
                        <section className="text-gray-600 body-font">
                            <div className="container px-5 py-24 mx-auto ">
                                <div className="flex flex-col text-center  w-full">
                                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                                        Account Deletion Form
                                    </h1>
                                    {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base"></p> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="dfd bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                        <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
                            Delete Your Account
                        </h2>
                        <div className="relative mb-4">
                            <label
                                htmlFor="email"
                                className="leading-7 text-sm text-gray-600"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Enter Your Email"
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <div className="relative mb-4">
                            <label
                                htmlFor="mobileNumber"
                                className="leading-7 text-sm text-gray-600"
                            >
                                Mobile Number
                            </label>
                            <input
                                type="number"
                                id="mobileNumber"
                                name="mobileNumber"
                                value={values.mobileNumber}
                                placeholder="Enter Your Mobile Number"
                                onChange={handleChange}
                                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>
                        <div className="relative mb-4">
                            <p
                                style={{display: "inline-block"}}
                                className="ml-2 leading-7 text-sm text-gray-600"
                            >
                                *Your name will be permanently removed from our Database
                            </p>
                        </div>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withSnackbar(DonorListForm);
