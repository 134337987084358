import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "./modalBody.css";


function getModalStyle() {
  const top = 35 
  const left = 35;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 270,
    height: 300,
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: "0 0.5rem 1rem rgba(0,0,0,0.2)",
    borderRadius: "0.3rem",
    padding: "1rem",
    [theme.breakpoints.down("lg")]: {
        height: "400px",
        width:"90%"
      },
      [theme.breakpoints.down("sm")]: {
        height: "400px",
        width:"80%"
      },
  },

}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {props.body}
      <button id="OK" onClick={handleClose}>Okay</button>
    </div>
  );

  return (
    <div style={{ display: "inline-block" }}>
      <button
        type="button"
        onClick={handleOpen}
				className="text-tertiary focus:outline-none"
        // style={{ outline: "none", color: "blue" }}
      >
        {props.frontText}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
