export const team = [
    {
        name: "Chirag Gupta",
        role: "Founder",
        image: "teamImages/chiragGupta.jpg",
        handle: "",
    },

    {
        name: "Karan Bangia",
        role: "Developer",
        image: "teamImages/karan.jpg",
        handle: "",
    },

    {
        name: "Prince Kumar Dhankhar",
        role: "Developer",
        image: "teamImages/prince.png",
        handle: "",
    },
    {
        name: "Paras Gupta",
        role: "Developer",
        image: "teamImages/ParasGupta.jpg",
        handle: "",
    },
    {
        name: "Jaey Motwani",
        role: "Content & Marketing team",
        image: "teamImages/JaeyMotwani.jpg",
        handle: "",
    },
    {
        name: "Govind Singh",
        role: "Content & Marketing team",
        image: "teamImages/GovindSingh.jpeg",
        handle: "",
    },
    {
        name: "Sakshi Bora",
        role: "Content & Marketing team",
        image: "teamImages/SakshiBora.jpeg",
        handle: "",
    },
    {
        name: "Athreya Kousgi",
        role: "Content & Marketing team",
        image: "teamImages/AthreyaKousgi.jpeg",
        handle: "",
    },
    {
        name: "Anusha Kasi",
        role: "Content & Marketing team",
        image: "teamImages/AnushaKasi.jpeg",
        handle: "",
    },

    // {
    //   name: "Garv Kejriwal",
    //   role: "Content & Marketing team",
    //   image: "teamImages/GarvKejriwal.jpg",
    //   handle: "",
    // },

    {
        name: "Srushti Gupta",
        role: "Content & Marketing team",
        image: "teamImages/SrushtiGupta.jpg",
        handle: "",
    },
    // {
    //   name: "Anirudh Sharma",
    //   role: "Content & Marketing team",
    //   image: "teamImages/Anirudh.jpeg",
    //   handle: "",
    // },
    //
    // {
    //   name: "Shruti Gupta",
    //   role: "Creative and Marketing Team",
    //   image: "teamImages/ShrutiGupta.jpg",
    //   handle: "",
    // },

    {
        name: "Arjun Singh",
        role: "Marketing Team",
        image: "teamImages/arjunSingh.jpeg",
        handle: "",
    },
];
