import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDaFACCE5v2yIZysOb5QaB_2J3TcEUw03Q",
  authDomain: "donorsarmy-b249b.firebaseapp.com",
  projectId: "donorsarmy-b249b",
  storageBucket: "donorsarmy-b249b.appspot.com",
  messagingSenderId: "959529354290",
  appId: "1:959529354290:web:486eed8f0b3eea3b50682b"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();
export const auth = firebase.auth;


