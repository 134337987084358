import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { bloodGroupList } from "../list/bloodGroupList";
import statesAndDistrictList from "../list/stateDistrictList";
import DatePicker from "./DatePicker";
import validator from "validator";
import { withSnackbar } from "notistack";
import firebase from "firebase";
import SimpleModal from "./SimpleModal";
import EligibilityModal from "./EligibilityModal";
import { useHistory, useParams } from "react-router-dom";
import { eligibilityModalBody, termsAndCondition } from "./ModalBody/ModalBody";
import Loader from "./Loader";

const SignUp = ({ enqueueSnackbar, setUser }) => {
  const [newUser, setNewUser] = useState(null);
  const history = useHistory();
  const defaultValues = {
    name: "",
    email: "",
    age: "",
    mobileNumber: "",
    state: "Select Your State",
    district: "Select Your District",
    pinCode: "",
    bloodGroup: "Select Your Blood Group",
    dateOfCovidPositive: "",
    covidReport: false,
    termsConditions: false
  };

  const defaultOTP = {
    otp: ""
  };

  const [otpValues, setOtpValues] = useState({ ...defaultOTP });
  const [t, setT] = useState({});
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [values, setValues] = useState({ ...defaultValues });
  const [indianStates, setIndianStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  // localStorage.setItem

  useEffect(() => {
    const stateArr = [defaultValues.state];
    statesAndDistrictList.forEach((state) => {
      stateArr.push(state.state);
    });
    setIndianStates(stateArr);
    refreshDistricts("");
  }, []);

  const { otp } = otpValues;
  let recaptchaVerifier;
  const sendOTP = () => {
    setLoading(true);
    recaptchaVerifier = window.recaptchaVerifier = new auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
      }
    );
    if (
      values.mobileNumber.length !== 10 ||
      !validator.isMobilePhone(values.mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    }
    const phoneNumber = `+91${values.mobileNumber}`;
    auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((e) => {
        document.getElementById("resend-otp").style.removeProperty("display");
        setTimeout(() => {
          document.getElementById("recaptcha-container").style = "display:none";
          document.getElementById("sendotp").style = "display:none";
          document.getElementById("mobilenumberblock").classList.add("flex");
          document
            .getElementById("mobilenumberblock")
            .classList.add("items-center");
        }, 500);
        setT(e);
        setLoading(false);
        enqueueSnackbar("OTP has been sent", {
          variant: "success",
          timeout: "2000"
        });
        setTimeout(() => {
          enqueueSnackbar("Now Verify Your OTP ", { variant: "warning" });
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Something Went Wrong ", { variant: "error" });
        document.getElementById("resend-otp").style.removeProperty("display");
        // document.getElementById("resend-otp").style="display:block";
      });
  };

  const reSend = () => {
    setLoading(true);
    document.getElementById("recaptcha-container-2").style = "display:block";
    recaptchaVerifier = window.recaptchaVerifier = new auth.RecaptchaVerifier(
      "recaptcha-container-2",
      { size: "normal" }
    );
    if (
      values.mobileNumber.length !== 10 ||
      !validator.isMobilePhone(values.mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    }
    const phoneNumber = `+91${values.mobileNumber}`;
    auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((e) => {
        setTimeout(() => {
          document.getElementById("recaptcha-container-2").innerHTML = "";
          document.getElementById("recaptcha-container-2").style =
            "display:none";
        }, 1000);
        setT(e);
        setLoading(false);
        enqueueSnackbar("OTP has been sent", {
          variant: "success",
          timeout: "2000"
        });
        setTimeout(() => {
          enqueueSnackbar("Now Verify Your OTP ", { variant: "warning" });
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Something Went Wrong ", { variant: "error" });
      });
  };

  const verify = () => {
    if (otp.length !== 6) {
      enqueueSnackbar("Invalid OTP", { variant: "error" });
      setOtpValues({ ...defaultOTP });
      return;
    }

    try {
      setLoading(true);
      t.confirm(otp)
        .then((result) => {
          // User signed in successfully.
          if (t.verificationId) {
            enqueueSnackbar(
              "You have successfully verified your mobile number",
              { variant: "success" }
            );
            document
              .getElementById("verifiedGreenTick")
              .style.removeProperty("display");
            document.getElementById("resend-otp").style = "display:none";
            document.getElementById("verify-otp").style = "display:none";
            document.getElementById("otp-input").style = "display:none";
          }

          setNewUser({
            isNewUser: true,
            uid: result.user.uid,
            email:values.email,
            phoneNumber: result.user.phoneNumber,
            lastSignInTime: result.user.metadata.lastSignInTime
          });

          setVerifyOTP(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(`${error.code.slice(5, 12)} OTP. Re-Enter the OTP`, {
            variant: "error"
          });
          setOtpValues({ ...defaultOTP });
        });
    } catch (e) {
      setLoading(false);
      if (e.message === "t.confirm is not a function") {
        enqueueSnackbar("Pls Send the OTP first", { variant: "error" });
        setOtpValues({ ...defaultOTP });
        return;
      }
    }
  };

  const refreshDistricts = (selectedState) => {
    const selectedStateObject = statesAndDistrictList.filter(
      (state) => state.state === selectedState
    );
    const districtArr = [defaultValues.district];

    selectedStateObject[0]?.districts?.forEach((district) => {
      districtArr.push(district);
    });

    setDistricts(districtArr);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    const tempVal =
      name === "covidReport" || name === "termsConditions" ? checked : value;
    if (name === "state") {
      refreshDistricts(value);
    }

    setValues((prev) => ({
      ...prev,
      [name]: tempVal
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(values).forEach((key) => {
      values[key] = values[key].toString().trim();
    });
    let {
      name,
      email,
      age,
      mobileNumber,
      state,
      district,
      pinCode,
      bloodGroup,
      termsConditions
    } = values;

    if (validator.isEmpty(name)) {
      enqueueSnackbar("Name should not be empty", { variant: "error" });
      return;
    } else if (!validator.isEmail(email)) {
      enqueueSnackbar("Enter a valid Email address", { variant: "error" });
      return;
    } else if (!validator.isInt(age, { min: 0, max: 100 })) {
      age = parseInt(age, 10);
      enqueueSnackbar("Age should be valid", { variant: "error" });
      return;
    } else if (
      mobileNumber.length !== 10 ||
      !validator.isMobilePhone(mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    } else if (validator.isEmpty(state) || state === defaultValues.state) {
      enqueueSnackbar("Please select a state", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(district) ||
      district === defaultValues.district
    ) {
      enqueueSnackbar("Please select a district", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(bloodGroup) ||
      bloodGroup === defaultValues.bloodGroup
    ) {
      enqueueSnackbar("Please select a blood group", { variant: "error" });
      return;
    } else if (
      !validator.isBoolean(termsConditions) ||
      termsConditions === "false"
    ) {
      enqueueSnackbar("Please Accept the Terms and Conditions", {
        variant: "error"
      });
      return;
    } else if (verifyOTP == false) {
      enqueueSnackbar("Invalid OTP", { variant: "error" });
      return;
    }
    setLoading(true);
    db.collection("beneficiary")
      .where("mobileNumber", "==", mobileNumber)
      .where("visible", "==", true)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0) {
          setLoading(false);
          return enqueueSnackbar("This mobile number is already registered", {
            variant: "warning"
          });
        } else {
          const obj = {
            name,
            email,
            age,
            mobileNumber,
            state,
            district,
            pinCode,
            bloodGroup,
            visible: true,
            createdAt: firebase.firestore.Timestamp.now()
          };

          return db
            .collection("beneficiary")
            .add(obj)
            .then(() => {
              setValues({
                ...defaultValues
              });
              setOtpValues({ ...defaultOTP });
              setLoading(false);
              localStorage.setItem("user", JSON.stringify(newUser));
              setUser(newUser);
              history.push(`/${newUser.uid}/userProfile`);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Some error occurred", { variant: "error" });
      });
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && <Loader />}

      <section className="text-gray-600 body-font py-5">
        <div className="container px-5 mx-auto flex flex-col md:flex-row items-center">

					{/*---------------------------------------1-----------------------------------*/}

          <div className="flex w-full lg:w-1/2  mb-2">
          <div className="h-full w-full flex flex-col lg:px-4">
          <div className="h-full">
             <h1 className="text-3xl lg:text-5xl mb-2 font-bold py-3" style={{
            fontFamily: `'Poppins', sans-serif`,
            // lineHeight:"44px",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            SignUp
            </h1>
						<p className="text-tertiary">Help People In Need</p>
          </div>

            <div className="w-full h-full" >
          <img src="/PagesImages/signUpPageImage.png" className="h-full w-full" />
        </div>
</div>
        </div>
		

						{/*---------------------------------------2-----------------------------------*/}

          <div className="dfd bg-primary rounded-3xl p-8 shadow-lg flex flex-col md:ml-auto w-full lg:w-1/2 mt-4 md:mt-0">
            <h2 className="text-white text-lg font-medium title-font mb-2">
              Registration Form For Beneficiary
            </h2>

            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-md text-white font-bold">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                placeholder="Enter Your Name"
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-md text-white font-bold"
              >
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter Your Email"
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="age" className="leading-7 text-md text-white font-bold">
                Age<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                id="age"
                name="age"
                value={values.age}
                onChange={handleChange}
                placeholder="Enter Your Age"
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div
              id="mobilenumberblock"
              className=" lg:flex lg:flex-row lg:items-center   "
            >
              <div className="relative mb-4 mr-2 w-22 lg:w-2/6">
                <label
                  htmlFor="mobileNumber"
                  className="leading-7 text-md text-white font-bold"
                >
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  placeholder="Enter Your Mobile Number"
                  onChange={handleChange}
                  className="w-full md:w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div
                id="verifiedGreenTick"
                className="w-5"
                style={{ display: "none" }}
              >
                <i
                  className="fas fa-check-circle"
                  style={{ color: "green" }}
                ></i>
              </div>

              <div className="lg:w-2/6 ">
                <button
                  type="submit"
                  id="sendotp"
                  onClick={sendOTP}
                  className=" mt-1.5 -mb-1 text-white bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-sm"
                >
                  Send OTP
                </button>
              </div>
            </div>

            <div id="recaptcha-container"></div>

            <div
              id="otp-input"
              className="lg:flex lg:flex-row lg:items-center mt-2"
            >
              <div className="relative mb-4 lg:w-3/6">
                <label
                  htmlFor="otp"
                  className="leading-7 text-md text-white font-bold"
                >
                  OTP<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="otp"
                  name="otp"
                  value={otpValues.otp}
                  placeholder="Please Enter rececived OTP"
                  onChange={(e) => {
                    setOtpValues({ ...defaultOTP, otp: e.target.value });
                  }}
                  className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="lg:w-3/6 ml-1 mt-1">
                <button
                  type="submit"
                  onClick={verify}
                  id="verify-otp"
                  className=" mt-2 text-white bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-10 focus:outline-none hover:bg-indigo-600 rounded-full text-sm"
                >
                  Verify
                </button>
                <button
                  type="button"
                  onClick={reSend}
                  id="resend-otp"
                  style={{ display: "none" }}
                  className="mt-1 mb-2 lg:mx-1 text-white bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-sm"
                >
                  Resend OTP
                </button>
                <div id="recaptcha-container-2"></div>
              </div>
            </div>

            <div className="relative mb-4">
              <label
                htmlFor="state"
                className="leading-7 text-md text-white font-bold"
              >
                State<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="state"
                value={values.state}
                onChange={handleChange}
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                {indianStates.map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="district"
                className="leading-7 text-md text-white font-bold"
              >
                District<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="district"
                value={values.district}
                onChange={handleChange}
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                {districts.map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="pinCode"
                className="leading-7 text-md text-white font-bold"
              >
                Pin Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                id="pinCode"
                name="pinCode"
                value={values.pinCode}
                placeholder="Enter Your Pin Code"
                onChange={handleChange}
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="bloodGroup"
                className="leading-7 text-md text-white font-bold"
              >
                Blood Group<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="bloodGroup"
                value={values.bloodGroup}
                onChange={handleChange}
                className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value={defaultValues.bloodGroup}>
                  {defaultValues.bloodGroup}
                </option>
                {bloodGroupList.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="relative mb-4">
              <input
                type="checkbox"
                id="termsConditions"
                name="termsConditions"
                value={values.termsConditions}
                onChange={handleChange}
                className="mt-3"
              />
              <label
                htmlFor="termsConditions"
                style={{ display: "inline-block" }}
                className="ml-2 leading-7 text-sm text-white font-normal"
              >
                I agree to the
                {' '}
                <SimpleModal
                  frontText="Terms and Conditions"
                  body={[termsAndCondition]}
                />
              </label>
            </div>
            <button
              type="submit"
              onClick={onSubmit}
              className="text-white bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-lg"
            >
              Submit
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withSnackbar(SignUp);
