import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import statesAndDistrictList from '../list/stateDistrictList';
import DatePicker from './DatePicker';
import validator from 'validator';
import { withSnackbar } from 'notistack';
import mailgun from 'mailgun-js';

const VolunteerForm = ({ enqueueSnackbar }) => {
    const defaultValues = {
        name: '',
        email: '',
        mobileNumber: '',
        state: 'Select Your State',
        district: 'Select Your District',
        locality: '',
        dateOfBirth: '',
        comments: '',
        disclaimer: false,
    };

    const [values, setValues] = useState({ ...defaultValues });
    const [indianStates, setIndianStates] = useState([]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        const stateArr = [defaultValues.state];
        statesAndDistrictList.forEach(state => {
            stateArr.push(state.state);
        });

        setIndianStates(stateArr);
        refreshDistricts('');
    }, [])

    const refreshDistricts = (selectedState) => {
        const selectedStateObject = statesAndDistrictList.filter(state => state.state === selectedState);
        const districtArr = [defaultValues.district];

        selectedStateObject[0]?.districts?.forEach(district => {
            districtArr.push(district);
        });

        setDistricts(districtArr);
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        const tempVal = name === "disclaimer" ? checked : value;
        if (name === 'state') {
            refreshDistricts(value);
        }

        setValues((prev) => ({
            ...prev, [name]: tempVal
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        Object.keys(values).forEach(key => {
            values[key] = values[key].toString().trim();
        })

        let { name, email, mobileNumber, state, district, locality, dateOfBirth, disclaimer, comments } = values;

        if (validator.isEmpty(name)) {
            enqueueSnackbar('Name should not be empty', { variant: 'error' });
            return;
        } else if (mobileNumber.length !== 10 || !validator.isMobilePhone(mobileNumber, 'en-IN')) {
            enqueueSnackbar('Enter valid 10 digit mobile number without country code', { variant: 'error' });
            return;
        } else if (!validator.isEmail(email)) {
            enqueueSnackbar('Enter a valid Email address', { variant: 'error' });
            return;
        } else if (!validator.isDate(dateOfBirth, { format: 'YYYY-MM-DD' })) {
            enqueueSnackbar('Please select your date of birth', { variant: 'error' });
            return;
        } else if (validator.isEmpty(state) || state === defaultValues.state) {
            enqueueSnackbar('Please select a state', { variant: 'error' });
            return;
        } else if (validator.isEmpty(district) || district === defaultValues.district) {
            enqueueSnackbar('Please select a district', { variant: 'error' });
            return;
        } else if (!validator.isBoolean(disclaimer) || disclaimer === 'false') {
            enqueueSnackbar('Please check the disclaimer', { variant: 'error' });
            return;
        }

        db.collection('volunteer').add({
            name,
            mobileNumber,
            email,
            dateOfBirth,
            state,
            district,
            locality,
            comments,
            disclaimer,
        })
            .then(() => {
                const DOMAIN = 'donorsarmy.com';
                const mg = mailgun({ apiKey: process.env.REACT_APP_MAILGUN_API_KEY, domain: DOMAIN, host: 'api.eu.mailgun.net', });
                const msg = {
                    to: values.email,
                    bcc: 'donorsarmy@gmail.com',
                    from: 'DonorsArmy <support@donorsarmy.com>',
                    subject: 'Welcome Onboard | Donors Army',
                    text: `Hi ${values.name},\n\nGreetings from Donor's Army.\n\nThank you for registering with us as a volunteer to support our mission of connecting plasma donors and plasma seekers.\n\nWe welcome you to Donor's Army family. As the next step, kindly join the Whatsapp group: https://chat.whatsapp.com/JQ4hZumhgR5BrMp4qkmGK2 and introduce yourself. This is where the real action happens and you can contribute the most.\n\nOnce you join the group, the admins will introduce you to the team. We can then start saving lives together! :)\n\nIn case, you are facing issues with joining groups, send us a text on donorsarmy@gmail.com\n\nHelping people, one step at a time.\n\nBest,\nTeam Donor's Army\n#SaveLives`,
                };
                mg.messages().send(msg, function (error, body) {
                    if (error) {
                    } else {
                        enqueueSnackbar(`Response received, you'll receive a link shortly`, { variant: 'success' })
                    }
                });
                setValues({
                    ...defaultValues
                });
                enqueueSnackbar('Thank you for your contribution', { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar('Some error occurred', { variant: 'error' });
            });
    }

    return (
        <div >
            <section className="text-gray-600 body-font py-5">
                <div className="container px-5 mx-auto flex flex-col md:flex-row items-center">
  {/*---------------------------------------1-----------------------------------*/}
        <div className="flex w-full lg:w-1/2  mb-2">
          <div className="h-full w-full flex flex-col lg:px-4">
          <div className="h-full">
          <h1 className="text-3xl lg:text-5xl mb-2 lg:mb-24 font-bold py-3" style={{
            fontFamily: `'Poppins', sans-serif`,
            // lineHeight:"44px",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            Register Yourself as
						Volunteer
            </h1>
          </div>

            <div className="w-full h-full" >
          <img src="/PagesImages/volunteerPageImage.png" className="h-full w-full" />
        </div>

        </div>
				
				</div>
                    
{/*---------------------------------------2-----------------------------------*/}

                    <div className="dfd bg-primary rounded-3xl p-8 shadow-lg flex flex-col md:ml-auto w-full lg:w-1/2 mt-10 md:mt-0">
                        <div className="relative mb-4">
                            <label htmlFor="name" className="leading-7 text-md text-white font-bold" >Name<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} placeholder="Enter Your Name" className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="mobileNumber" className="leading-7 text-md text-white font-bold" >Mobile Number<span style={{ color: 'red' }}>*</span></label>
                            <input type="number" id="mobileNumber" name="mobileNumber" value={values.mobileNumber} placeholder="Enter Your Mobile Number" onChange={handleChange} className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-md text-white font-bold"  >Email<span style={{ color: 'red' }}>*</span></label>
                            <input type="email" id="email" name="email" value={values.email} onChange={handleChange} placeholder="Enter Your Email" className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="dateOfBirth" className="leading-7 text-md text-white font-bold">Date of birth<span style={{ color: 'red' }}>*</span></label>
                            {<DatePicker setDateValue={handleChange} value={values.dateOfBirth} name="dateOfBirth" />}
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="state" className="leading-7 text-md text-white font-bold" >State<span style={{ color: 'red' }}>*</span></label>
                            <select name="state" value={values.state} onChange={handleChange} className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                {indianStates.map((item) => { return <option key={item} value={item}>{item}</option> })}
                            </select>
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="district" className="leading-7 text-md text-white font-bold" >District<span style={{ color: 'red' }}>*</span></label>
                            <select name="district" value={values.district} onChange={handleChange} className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                {districts.map((item) => { return <option key={item} value={item}>{item}</option> })}
                            </select>
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="locality" className="leading-7 text-md text-white font-bold" >Locality</label>
                            <input type="text" id="locality" name="locality" value={values.locality} placeholder="Enter Your Locality" onChange={handleChange} className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="comments" className="leading-7 text-md text-white font-bold" >Any Inputs/Comments</label>
                            <textarea id="comments" name="comments" value={values.comments} placeholder="Anything you want to tell..." onChange={handleChange} className="w-full bg-white rounded-2xl border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <input type="checkbox" id="disclaimer" name="disclaimer" value={values.disclaimer} onChange={handleChange} className="mt-3" />
                            <label htmlFor="disclaimer" style={{ display: 'inline' }} className="ml-2 leading-7 text-md text-white font-bold">By Checking this Box, you agree to share your personal information with us for the use of internal communication.
                                </label>
                        </div>
                        <button type="submit" onClick={onSubmit} className="text-white bg-gradient-to-t from-tertiary to-secondary 0 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-lg">Submit</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default withSnackbar(VolunteerForm);
