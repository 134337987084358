import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { bloodGroupList } from "../list/bloodGroupList";
import statesAndDistrictList from "../list/stateDistrictList";
import DatePicker from "./DatePicker";
import validator from "validator";
import { withSnackbar } from "notistack";
import SimpleModal from "./SimpleModal";
import EligibilityModal from "./EligibilityModal";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import {
  eligibilityPlasmaModalBody,
  eligibilityBloodModalBody,
  termsAndCondition
} from "./ModalBody/ModalBody";
import Loader from "./Loader";

const UserProfile = ({ enqueueSnackbar, user }) => {
  const defaultValues = {
    name: "",
    email: "",
    age: "",
    mobileNumber: "",
    state: "Select Your State",
    district: "Select Your District",
    pinCode: "",
    bloodGroup: "Select Your Blood Group",
    donor: false,
    dateOfCovidPositive: "",
    covidReport: false,
    termsConditions: true
  };

  const defaultOTP = {
    otp: ""
  };

  const [otpValues, setOtpValues] = useState({ ...defaultOTP });
  const [t, setT] = useState({});
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [values, setValues] = useState({ ...defaultValues });
  const [indianStates, setIndianStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docID, setDocID] = useState(null);
  const [checkDonorStatus, setCheckDonorStatus] = useState(undefined);
  const [eligibilityTrigger, setEligibilityTrigger] = useState(false);
  useEffect(() => {
    db.collection("beneficiary")
      .where("mobileNumber", "==", user.phoneNumber.slice(3))
      .where("visible", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setDocID(doc.id);
          setCheckDonorStatus(doc.data().donor);
          setValues({
            ...values,

            name: doc.data().name,
            email: doc.data().email,
            age: doc.data().age,
            mobileNumber: doc.data().mobileNumber,
            state: doc.data().state,
            district: doc.data().district,
            donor: doc.data().donor ? doc.data().donor : false,
            pinCode: doc.data().pinCode,
            bloodGroup: doc.data().bloodGroup
          });
          refreshDistricts(doc.data().state);
        });
      });

    const stateArr = [defaultValues.state];
    statesAndDistrictList.forEach((state) => {
      stateArr.push(state.state);
    });
    setIndianStates(stateArr);
  }, []);

  const refreshDistricts = (selectedState) => {
    const selectedStateObject = statesAndDistrictList.filter(
      (state) => state.state === selectedState
    );
    const districtArr = [defaultValues.district];

    selectedStateObject[0]?.districts?.forEach((district) => {
      districtArr.push(district);
    });

    setDistricts(districtArr);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "donor") {
      if (value === "true") {
        setEligibilityTrigger(true);
      } else {
        setEligibilityTrigger(false);
      }
    }
    if (name === "state") {
      refreshDistricts(value);
    }
    setValues((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Object.keys(values).forEach((key) => {
    //   values[key] = values[key].toString().trim();
    // });
    let {
      name,
      email,
      age,
      mobileNumber,
      state,
      district,
      pinCode,
      bloodGroup,
      dateOfCovidPositive,
      covidReport,
      donor,
      termsConditions
    } = values;

    if (validator.isEmpty(name)) {
      enqueueSnackbar("Name should not be empty", { variant: "error" });
      return;
    } else if (!validator.isEmail(email)) {
      enqueueSnackbar("Enter a valid Email address", { variant: "error" });
      return;
    } else if (!validator.isInt(age, { min: 0, max: 100 })) {
      age = parseInt(age, 10);
      enqueueSnackbar("Age should be valid", { variant: "error" });
      return;
    } else if (
      mobileNumber.length !== 10 ||
      !validator.isMobilePhone(mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    } else if (validator.isEmpty(state) || state === defaultValues.state) {
      enqueueSnackbar("Please select a state", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(district) ||
      district === defaultValues.district
    ) {
      enqueueSnackbar("Please select a district", { variant: "error" });
      return;
    } else if (!validator.isInt(pinCode, { min: 100000, max: 999999 })) {
      enqueueSnackbar("Please enter a valid Pin Code", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(bloodGroup) ||
      bloodGroup === defaultValues.bloodGroup
    ) {
      enqueueSnackbar("Please select a blood group", { variant: "error" });
      return;
    } else if (termsConditions === false) {
      enqueueSnackbar("Please Accept the Terms and Conditions", {
        variant: "error"
      });
      return;
    }

    setLoading(true);
    db.collection("beneficiary")
      .where("mobileNumber", "==", mobileNumber)
      .where("visible", "==", true)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0) {
          querySnapshot.forEach((doc) => {
            setLoading(false);
            return db
              .collection("beneficiary")
              .doc(doc.id)
              .update({
                age,
                state,
                district,
                pinCode,
                donor,
                bloodGroup
              })
              .then(() => {
                document.getElementById("alert").innerText =
                  "Your Status Updated!!";
                document
                  .getElementById("registration_status")
                  .scrollIntoView({ behavior: "smooth" });

                setTimeout(() => {
                  document.getElementById("alert").innerText = "";
                }, 4000);

                db.collection("beneficiary")
                  .doc(docID)
                  .get()
                  .then((doc) => {
                    setCheckDonorStatus(doc.data().donor);
                  });
                setEligibilityTrigger(false);
                // setValues({
                //   ...defaultValues
                // });
                setLoading(false);
                enqueueSnackbar("Thank you for your contribution", {
                  variant: "success"
                });
              });
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Some error occurred", { variant: "error" });
      });
  };

  return (
    <div style={{ position: "relative" }}>
      {/* {params.type === "plasma" && (
        <EligibilityModal body={[eligibilityPlasmaModalBody]} />
      )} */}

      {/* {params.type === "blood" && (
        <EligibilityModal body={[eligibilityBloodModalBody]} />
      )} */} 
      {user.isNewUser && (
        <EligibilityModal body={[eligibilityBloodModalBody]} />
      )}
      {eligibilityTrigger && (
        <EligibilityModal body={[eligibilityBloodModalBody]} />
      )}
      {loading && <Loader />}

      <section className="text-gray-600 body-font py-5">
        <div className="container px-5 py-5 mx-auto flex flex-col items-center">
          <div>
            <section className="text-gray-600 body-font">
              <div className="container px-5 py-8 mx-auto w-screen">
                <div className="flex flex-row items-center justify-between text-left rounded-lg shadow-lg px-5 p-3 bg-primary-dark w-full">
                  <h1
                    id="registration_status"
                    className="sm:text-3xl text-2xl mt-2 font-semibold title-font mb-2 text-white"
                  >
                    User Profile<br />{" "}
                    <span className="text-sm px-1 font-medium">
                      Update your Details
                    </span>
                  </h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="white"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 7.001c0 3.865-3.134 7-7 7s-7-3.135-7-7c0-3.867 3.134-7.001 7-7.001s7 3.134 7 7.001zm-1.598 7.18c-1.506 1.137-3.374 1.82-5.402 1.82-2.03 0-3.899-.685-5.407-1.822-4.072 1.793-6.593 7.376-6.593 9.821h24c0-2.423-2.6-8.006-6.598-9.819z" />
                  </svg>
                </div>
              </div>
            </section>
          </div>
          <div className="dfd bg-gray-100 rounded-lg p-8 flex flex-col shadow-lg md:ml-auto w-full sm:mt-5 md:mt-0">
            <p
              id="alert"
              className="text-red-500 font-bold text-center mb-1"
            ></p>
            <h2 className="text-gray-900 text-xl font-bold  title-font mb-2">
              {/* {params.type === "plasma"
                ? "Registration Form For Plasma Donation"
                : "Registration Form For Blood Donation"} */}{" "}
              Credentials
            </h2>
            {checkDonorStatus === "true" ? (
              <span>
                Status:{" "}
                <span className="text-green-500 font-bold ">
                  Registered
                  <span class="ml-2 h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-green-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                  </span>
                </span>
              </span>
            ) : (
              <span>
                Status:{" "}
                <span className="text-red-500 font-bold ">
                  Unregistered
                  <span class="ml-2 h-3 w-3">
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                  </span>
                </span>
              </span>
            )}
            {/* {params.type === "plasma" && (
              <p className="mb-4">
                <b> Only For Covid Recovered Patient</b>
              </p>
            )} */}

            <div className="relative mb-4 mt-2">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                type="text"
                id="name"
                name="name"
                value={values.name}
                // onChange={handleChange}
                placeholder="Enter Your Name"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                type="email"
                id="email"
                name="email"
                value={values.email}
                // onChange={handleChange}
                placeholder="Enter Your Email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="age" className="leading-7 text-sm text-gray-600">
                Age<span style={{ color: "red" }}>*</span>
              </label>
              <input
                // disabled
                type="number"
                id="age"
                name="age"
                value={values.age}
                onChange={handleChange}
                placeholder="Enter Your Age"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div
              id="mobilenumberblock"
              className=" lg:flex lg:flex-row lg:items-center   "
            >
              <div className="relative mb-4   lg:w-full">
                <label
                  htmlFor="mobileNumber"
                  className="leading-7 text-sm text-gray-600"
                >
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  disabled
                  type="number"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  placeholder="Enter Your Mobile Number"
                  // onChange={handleChange}
                  className="w-full md:w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>

            <div className="relative mb-4">
              <label
                htmlFor="state"
                className="leading-7 text-sm text-gray-600"
              >
                State<span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="state"
                value={values.state}
                onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                {indianStates.map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="district"
                className="leading-7 text-sm text-gray-600"
              >
                District<span style={{ color: "red" }}>*</span>
              </label>
              <select
                value={values.district}
                name="district"
                onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                {districts.map((district) => (
                  <option key={district}>{district}</option>
                ))}
              </select>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="pinCode"
                className="leading-7 text-sm text-gray-600"
              >
                Pin Code<span style={{ color: "red" }}>*</span>
              </label>
              <input
                // disabled
                type="number"
                id="pinCode"
                name="pinCode"
                value={values.pinCode}
                placeholder="Enter Your Pin Code"
                onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            <div className="relative mb-4">
              <label
                htmlFor="bloodGroup"
                className="leading-7 text-sm text-gray-600"
              >
                Blood Group<span style={{ color: "red" }}>*</span>
              </label>
              <select
                // disabled
                name="bloodGroup"
                value={values.bloodGroup}
                onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1.5 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value={defaultValues.bloodGroup}>
                  {defaultValues.bloodGroup}
                </option>
                {bloodGroupList.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col md:flex-row mt-2 ">
              <div className="relative mb-4">
                <input
                  type="radio"
                  id="true"
                  name="donor"
                  value={true}
                  onChange={handleChange}
                  className="mt-3 cursor-pointer"
                />
                <label
                  htmlFor="true"
                  style={{ display: "inline-block" }}
                  className="ml-2 mr-4 leading-7 text-lg text-gray-600 cursor-pointer"
                >
                  Register yourself
                </label>
              </div>
              <div className="relative mb-4 md:ml-8">
                <input
                  type="radio"
                  id="false"
                  name="donor"
                  value={false}
                  onChange={handleChange}
                  className="mt-3 cursor-pointer"
                />
                <label
                  htmlFor="false"
                  style={{ display: "inline-block" }}
                  className="ml-2 leading-7 text-lg text-gray-600 cursor-pointer"
                >
                  Unregister yourself
                </label>
              </div>
            </div>
            <div className="relative mb-4 mt-2">
              <p className="font-semibold">
                Delete Your Account
                <Link to="/remove-donor">
                  <span className="ml-1 text-medium text-red-500 font-bold underline">
                    Click Here
                  </span>
                </Link>
              </p>
            </div>
            <button
              //-_-_-_-_-_->IMPORTANT DON`T REMOVE<-_-_-_-_-_-_-_- hidden={(checkDonorStatus==="false"?false:true) && (values.donor==="true")}
              type="submit"
              onClick={onSubmit}
              className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Submit
            </button>
            {/*  -_-_-_-_-_->IMPORTANT DON`T REMOVE<-_-_-_-_-_-_-_-  <p className="text-red-500 font-bold text-center mt-1 mb-1" hidden={checkDonorStatus==="true"?false:true}>You are already Registered</p> */}
            <p className="mt-2 animate-pulse font-medium ">
              * Your Information will be available in public domain until you
              unregister <span className="lg:ml-0  ml-1"> yourself.</span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withSnackbar(UserProfile);
