import React, { useState } from "react";
import { auth, db } from "../firebase";
import validator from "validator";
import { withSnackbar } from "notistack";
import { Link, Redirect, useHistory } from "react-router-dom";
import Loader from "./Loader";
import firebase from "firebase";

const Login = ({ enqueueSnackbar, setUser }) => {
  const history = useHistory();

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [t, setT] = useState({});
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  let recaptchaVerifier;

  const dbCheckOtpSend = () => {
    setLoading(true);
    if (
      mobileNumber.length !== 10 ||
      !validator.isMobilePhone(mobileNumber, "en-IN")
    ) {
      setLoading(false);
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    }

    db.collection("beneficiary")
      .where("mobileNumber", "==", mobileNumber)
      .where("visible", "==", true)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length === 0) {
          throw new Error("mobile number not found");
        } else {
          sendOTP();
          querySnapshot.forEach((doc) => {
            setEmail(doc.data().email);
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.message === "mobile number not found") {
          enqueueSnackbar("Pls register yourself before logging in", {
            variant: "warning"
          });
          return;
        }
        enqueueSnackbar("Some error occurred", { variant: "error" });
        return;
      });
  };
  const sendOTP = () => {
    recaptchaVerifier = window.recaptchaVerifier = new auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
      }
    );

    const phoneNumber = `+91${mobileNumber}`;

    auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((e) => {
        document.getElementById("resend-otp").style.removeProperty("display");
        setTimeout(() => {
          document.getElementById("recaptcha-container").style = "display:none";
          document.getElementById("sendotp").style = "display:none";
          // document.getElementById("mobilenumberblock").classList.add("flex");
          // document
          //   .getElementById("mobilenumberblock")
          //   .classList.add("items-center");
        }, 500);
        setT(e);
        setLoading(false);
        enqueueSnackbar("OTP has been sent", {
          variant: "success",
          timeout: "2000"
        });
        setTimeout(() => {
          enqueueSnackbar("Now Verify Your OTP ", { variant: "warning" });
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Something Went Wrong ", { variant: "error" });
        document.getElementById("resend-otp").style.removeProperty("display");
        // document.getElementById("resend-otp").style="display:block";
      });
  };

  const reSend = () => {
    setLoading(true);
    document.getElementById("recaptcha-container-2").style = "display:block";
    recaptchaVerifier = window.recaptchaVerifier = new auth.RecaptchaVerifier(
      "recaptcha-container-2",
      { size: "normal" }
    );
    if (
      mobileNumber.length !== 10 ||
      !validator.isMobilePhone(mobileNumber, "en-IN")
    ) {
      enqueueSnackbar(
        "Enter valid 10 digit mobile number without country code",
        { variant: "error" }
      );
      return;
    }
    const phoneNumber = `+91${mobileNumber}`;
    auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((e) => {
        setTimeout(() => {
          document.getElementById("recaptcha-container-2").innerHTML = "";
          document.getElementById("recaptcha-container-2").style =
            "display:none";
        }, 1000);
        setT(e);
        setLoading(false);
        enqueueSnackbar("OTP has been sent", {
          variant: "success",
          timeout: "2000"
        });
        setTimeout(() => {
          enqueueSnackbar("Now Verify Your OTP ", { variant: "warning" });
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Something Went Wrong ", { variant: "error" });
      });
  };

  const verify = () => {
    if (otp.length !== 6) {
      enqueueSnackbar("Invalid OTP", { variant: "error" });
      setOtp("");
      return;
    }

    try {
      setLoading(true);
      t.confirm(otp)
        .then((result) => {
          // User signed in successfully.

          if (t.verificationId) {
            enqueueSnackbar(
              "You have successfully verified your mobile number",
              { variant: "success" }
            );
          }

          const newUser = {
            uid: result.user.uid,
            phoneNumber: result.user.phoneNumber,
            email: email,
            isNewUser: false,
            createdAt: result.user.createdAt,
            lastSignInTime: result.user.metadata.lastSignInTime
          };
          localStorage.setItem("user", JSON.stringify(newUser));
          setUser(newUser);
          setVerifyOTP(true);
          setLoading(false);
          history.push(`/${newUser.uid}/userProfile`);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(`${error.code.slice(5, 12)} OTP. Re-Enter the OTP`, {
            variant: "error"
          });
          setOtp("");
        });
    } catch (e) {
      setLoading(false);
      if (e.message === "t.confirm is not a function") {
        enqueueSnackbar("Pls Send the OTP first", { variant: "error" });
        setOtp("");
        return;
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && <Loader />}

      <section className="text-gray-600 body-font py-5">
        <div className="container px-5 mx-auto flex flex-col md:flex-row items-center">


					{/*---------------------------------------1-----------------------------------*/}


				  <div className="flex w-full lg:w-1/2  mb-2">
          <div className="h-full w-full flex flex-col lg:px-4">
          <div className="h-full">
          <h1 className="text-3xl lg:text-5xl mb-2 font-bold py-3" style={{
            fontFamily: `'Poppins', sans-serif`,
            // lineHeight:"44px",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            Login
            </h1>
						<p className="text-tertiary">To Find / Register for Blood Donor</p>
          </div>

            <div className="w-full h-full" >
          <img src="/PagesImages/loginPageImage.png" className="h-full w-full" />
        </div>

        </div>
				
				</div>


	{/*---------------------------------------2-----------------------------------*/}


          <div className="dfd bg-primary rounded-3xl p-8 flex flex-col shadow-lg md:ml-auto w-full lg:w-1/2 sm:mt-5 md:mt-0">
            <div
              id="mobilenumberblock"
              className=" lg:flex lg:flex-col lg:items-start"
            >
              <div className="relative mb-4 w-full">
                <label
                  htmlFor="mobileNumber"
                  className="leading-7 text-md text-white font-bold"
                >
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={mobileNumber}
                  placeholder="Enter Your Mobile Number"
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>

              <div className="w-full lg:w-2/6 lg:mt-1  ">
                <button
                  type="submit"
                  id="sendotp"
                  onClick={dbCheckOtpSend}
                  className=" mt-1.5 -mb-1 text-white  w-full bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-md"
                >
                  Send OTP
                </button>
              </div>
            </div>

            <div id="recaptcha-container"></div>

            <div
              id="otp-input"
              className="lg:flex lg:flex-col lg:items-start mt-2"
            >
              <div className="relative mb-4 w-full">
                <label
                  htmlFor="otp"
                  className="leading-7 text-md text-white font-bold"
                >
                  OTP<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="otp"
                  name="otp"
                  value={otp}
                  placeholder="Please Enter rececived OTP"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  className="w-full bg-white rounded-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="lg:w-2/6 ml-1 ">
                <button
                  type="submit"
                  onClick={verify}
                  id="verify-otp"
                  className=" mt-2 text-white bg-gradient-to-t from-tertiary to-secondary w-full border-0 py-2 px-10 focus:outline-none hover:bg-indigo-600 rounded-full text-md"
                >
                  Verify
                </button>
                <button
                  type="button"
                  onClick={reSend}
                  id="resend-otp"
                  style={{ display: "none" }}
                  className="mt-1 mb-2 lg:mx-1  w-full text-white bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-8 focus:outline-none focus:bg-red-500 hover:bg-indigo-600 rounded-full text-md"
                >
                  Resend OTP
                </button>
                <div id="recaptcha-container-2"></div>
              </div>
            </div>

            {/*  -_-_-_-_-_->IMPORTANT DON`T REMOVE<-_-_-_-_-_-_-_-  <p className="text-red-500 font-bold text-center mt-1 mb-1" hidden={checkDonorStatus==="true"?false:true}>You are already Registered</p> */}
            <p className="mt-2 font-medium mt-4 text-white">
              Don't have an account,{" "}
              <Link to="/signUp">
                <span className="text-tertiary font-bold">click here</span>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withSnackbar(Login);
