import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { withSnackbar } from "notistack";
import { DataGrid } from "@material-ui/data-grid";
import FilterDonor from "./FilterDonor";
import validator from "validator";
import { Redirect } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import Loader from "./Loader";

const FindDonor = ({ enqueueSnackbar }) => {
  const [donors, setDonors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [selectedDonorID, setSelectedDonorID] = useState(undefined);
  // const [{ user }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
  }, []);
  const defaultFilter = {
    requirementType: "blood",
    state: "Not Selected",
    district: "No state selected",
    districtWithState: "Not Selected",
    pinCode: "",
    bloodGroup: "Not Selected",
  };

  const [filter, setFilter] = useState({ ...defaultFilter });

  const columns = [
    { field: "name", headerName: "Full Name", flex: 1 },
    {
      field: "bloodGroup",
      headerName: "Blood Group",
      flex: 0.8,
    },
    // {
    //     field: 'state',
    //     headerName: 'State',
    //     flex: 1,
    // },
    {
      field: "district",
      headerName: "District",
      flex: 1,
    },
    {
      field: "pinCode",
      headerName: "Pin Code",
      flex: 0.7,
    },
    {
      field: "filterMobileNumber",
      headerName: "Phone number(Tap to view)",
      flex: 0.8,
    },
  ];

  const rowSelectedHandler = (e) => {
    const ID = e.data.id;
    if (ID === selectedDonorID) {
      return;
    }

    const tempDonors = donors;

    if (selectedDonorID) {
      const oldIdx = tempDonors.findIndex(
        (donor) => donor.id === selectedDonorID
      );
      tempDonors[oldIdx].filterMobileNumber = `${tempDonors[
        oldIdx
      ].mobileNumber.substring(0, 5)}xxxxx`;
    }

    const newIdx = tempDonors.findIndex((donor) => donor.id === ID);
    tempDonors[newIdx].filterMobileNumber = tempDonors[newIdx].mobileNumber;

    setDonors(tempDonors);
    setSelectedDonorID(ID);
  };

  const searchDonors = () => {
    if (filter.state === defaultFilter.state) {
      enqueueSnackbar("State needs to be selected", { variant: "error" });
      return;
    } else if (
      validator.isEmpty(filter.bloodGroup) ||
      filter.bloodGroup === defaultFilter.bloodGroup
    ) {
      enqueueSnackbar("Please select a blood group", { variant: "error" });
      return;
    } else if (
      !validator.isEmpty(filter.pinCode) &&
      !validator.isInt(filter.pinCode, {
        min: 100000,
        max: 999999,
      })
    ) {
      enqueueSnackbar("Please enter a valid Pin Code", { variant: "error" });
      return;
    }
    enqueueSnackbar("Searching", { variant: "info", autoHideDuration: 500 });
    setLoading(true);

    let query = db
      .collection("beneficiary").where("visible","==",true)
      .where("donor", "==", "true")
      .where("state", "==", filter.state);

    if (filter.bloodGroup !== "all") {
      query = query.where("bloodGroup", "==", filter.bloodGroup);
    }

    if (validator.isInt(filter.pinCode, { min: 100000, max: 999999 })) {
      query = query.where("pinCode", "==", filter.pinCode);
    }
    if (
      filter.district !== defaultFilter.district &&
      filter.district !== defaultFilter.districtWithState
    ) {
      query = query.where("district", "==", filter.district);
    }

    query
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({
            ...doc.data(),
            bloodGroup: doc.data().bloodGroup.split("(")[0].trim(),
            filterMobileNumber: `${doc
              .data()
              .mobileNumber.substring(0, 5)}xxxxx`,
            id: doc.id,
          });
        });

        setLoading(false);
        setDonors(arr);
        if (donors.length == 0) {
          setCheck(true);
        }
        if (arr.length === 0) {
          enqueueSnackbar("No donors found for the specified criteria", {
            variant: "error",
          });
          return;
        }
      })
      .catch((err) =>
        enqueueSnackbar("Error fetching data", { variant: "error" })
      );
  };

  return (
    <>
      <FilterDonor
        filter={filter}
        setFilter={setFilter}
        defaultFilter={defaultFilter}
      />
      <div className="flex flex-row w-full justify-center items-center">
        <button
          onClick={searchDonors}
          className="mx-1 mt-4 w-1/2 md:w-auto text-white  bg-gradient-to-t from-tertiary to-secondary border-0 py-2 px-4 rounded-full focus:outline-none hover:bg-indigo-600 rounded text-lg"
        >
          Search
        </button>
      </div>
      {donors.length > 0 && (
        <div style={{ overflowX: "scroll" }}>
          <div style={{ maxWidth: "100vw", marginTop: "2em" }}>
            <div style={{ height: 400, minWidth: "1000px" }}>
              <DataGrid
                loading={loading}
                disableColumnMenu
                rows={donors}
                columns={columns}
                pageSize={50}
                onRowSelected={rowSelectedHandler}
              />
            </div>
          </div>
        </div>
      )}
      {check && donors.length == 0 && (
        <div
          style={{
            color: "red",
            display: "flex",
            justifyContent: "center",
            margin: "10% auto",
            fontSize: "20px",
          }}
        >
          No donor found as per your requirements
        </div>
      )}
    </>
  );
};

export default withSnackbar(FindDonor);
