import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { bloodGroupList } from '../list/bloodGroupList';
import statesAndDistrictList from '../list/stateDistrictList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        alignContent: 'center',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export default function FilterDonor({ filter, setFilter, defaultFilter }) {
    const classes = useStyles();
    const [requirementType, setRequirementType] = useState([]);
    const [indianStates, setIndianStates] = useState([]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        const stateArr = [defaultFilter.state];
        statesAndDistrictList.forEach(state => {
            stateArr.push(state.state);
        });
        setRequirementType(["Blood"])
        setIndianStates(stateArr);
        refreshDistricts('');
    }, [])

    const refreshDistricts = (selectedState) => {
        const selectedStateObject = statesAndDistrictList.filter(state => state.state === selectedState);

        const districtArr = [];

        if (selectedState == defaultFilter.state || selectedState == '') {
            districtArr.push(defaultFilter.district)
        } else {
            districtArr.push(defaultFilter.districtWithState)
            selectedStateObject[0]?.districts?.forEach(district => {
                districtArr.push(district);
            });
        }

        setDistricts(districtArr);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name == 'state') {
            refreshDistricts(value);
        }
        setFilter((prev) => ({
            ...prev, [name]: value
        }));
    };


    return (
        <div className={classes.root}>
            <h1 style={{fontSize: '1.5em', textAlign: 'center'}} >Filter Donor According to your Requirements</h1>
            <Accordion expanded={false}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>
                        <label htmlFor="requirementType">Requirement Type<span style={{ color: 'red' }}>*</span></label>
                    </Typography>
                    <select name="requirementType" value={filter.requirementType} onChange={handleChange} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        {requirementType.map((item) => { return <option key={item}>{item}</option> })}
                    </select>
                </AccordionSummary>
            </Accordion>
            <Accordion expanded={false}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>
                        <label htmlFor="state">State<span style={{ color: 'red' }}>*</span></label>
                    </Typography>
                    <select name="state" value={filter.state} onChange={handleChange} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        {indianStates.map((item) => { return <option key={item}>{item}</option> })}
                    </select>
                </AccordionSummary>
            </Accordion>
            <Accordion expanded={false}>
                <AccordionSummary
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className={classes.heading}>
                        <label htmlFor="district">District (Optional)</label></Typography>
                    <select name="district" value={filter.district} onChange={handleChange} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        {districts.map((item) => { return <option key={item}>{item}</option> })}
                    </select>
                </AccordionSummary>
            </Accordion>
            <Accordion expanded={false}>
                <AccordionSummary
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography className={classes.heading}>
                        <label htmlFor="bloodGroup">Blood Group<span style={{ color: 'red' }}>*</span></label></Typography>
                    <select name="bloodGroup" value={filter.bloodGroup} onChange={handleChange} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        <option value={defaultFilter.bloodGroup}>{defaultFilter.bloodGroup}</option>
                        <option value="all">Show All</option>
                        {bloodGroupList.map((item) => { return <option key={item}>{item}</option> })}
                    </select>
                </AccordionSummary>
            </Accordion>
            {/* <Accordion expanded={false}>
                <AccordionSummary
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography className={classes.heading}>
                        <label htmlFor="pinCode">Pin Code</label></Typography>
                    <input name="pinCode" value={filter.pinCode} onChange={handleChange} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </input>
                </AccordionSummary>
            </Accordion> */}
        </div>
    );
}