import { Button } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React from "react";
import { Redirect } from "react-router";
import { db, auth } from "../firebase";

const MyRegistrations = ({
  people,
  enqueueSnackbar,
  closeSnackbar,
  removePersonFromList
}) => {
  const deletePerson = (person, key) => {
    db.collection("beneficiary")
      .doc(person.id)
      .update({
        visible: false
      })
      .then(() => {
        closeSnackbar(key);
        enqueueSnackbar("Deleted", { variant: "success" });
        removePersonFromList(person);
        // defaultAuth.deleteUser()
        auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("user");
            <Redirect to="/" />;
          });
      })
      .catch((err) => console.error(err));
  };

  const snackbarDeleteAction = (key, person) => (
    <>
      <Button onClick={() => deletePerson(person, key)}>Yes</Button>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        No
      </Button>
    </>
  );

  const deleteDonorHandler = (person) => {
    enqueueSnackbar(`Remove ${person.name}?`, {
      variant: "warning",
      autoHideDuration: 3000,
      action: (key) => snackbarDeleteAction(key, person)
    });
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="text-2xl font-medium title-font mb-4 text-gray-900 tracking-widest">
            Confirmation
          </h1>
        </div>
        <div className="flex flex-col -m-4">
          {people.map((person) => {
            return (
              <div key={person.id} className="p-4 lg:w-full">
                <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                  <div className="flex flex-col p-5 shadow-lg rounded-lg">
                    <h3 className="title-font font-bold text-lg uppercase text-gray-900">
                      {person.name}
                    </h3>
                    <h3 className="text-gray-500 mb-3">{`${person.district}, ${person.state} - ${person.pinCode}`}</h3>
                    <p className="mb-4 font-bold">
                      Age:{" "}
                      <span className="font-normal">{` ${person.age}`} </span>
                      <br />
                      Blood Group:{" "}
                      <span className="font-normal">{` ${person.bloodGroup}`}</span>
                    </p>
                    <button
                      className="flex mx-1 justify-center text-white bg-red-500 border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg"
                      onClick={() => deleteDonorHandler(person)}
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default withSnackbar(MyRegistrations);
