export const termsAndCondition = (
  <div>
    <p className="heading">Terms and conditions:</p>
    <p className="text">
      This is to inform everyone that we at donorsarmy.com are group of people
      who are inclined to do some good work towards welfare of society. And we
      are doing this work considering our Individual Social Responsibility
      (ISR). You are at the right place to prove the myth wrong of not being
      able to contribute towards society until now. As the adage goes{" "}
      <b>“Where there is a will, there is a way.”</b>
    </p>
    <p className="terms">
      By submitting this form, you agree to the following:
    </p>
    <ul id="modalul">
      <li>
        Donors Army is just a mediator/transparent platform for blood seekers to
        directly reach the Donors.
      </li>
      <li>
        We do not indulge into any medical services such as testing,
        transfusion, storage or etc. and we cannot be held liable for any
        complications during the process.
      </li>
      <li>
        This is purely a <b>NON-PROFIT ORGANISATION</b> .
      </li>
      <li>
        We do not engage ourselves into any monetary benefits nor do we promote
        such wrong doings as this is purely a voluntary activity.
      </li>
      <li>
        The donors and seekers are requested to cooperate and provide/verify the
        relevant documents required, if any throughout the process.
      </li>
      <li>
        If you notice any kind of misconduct or illegal activity during the
        process, you are requested to report such an act to us/government
        authorities.
      </li>
      <li>
        TThe data and information you can access on this website has been
        developed and compiled by Donors Army.
      </li>
      <li>
        We strictly discourage the misuse of the donor’s data publicly available
        on the website until they unregister.
      </li>
      <li>
        This information provided above is complete and accurate to the best of
        your knowledge.
      </li>
      <li>
        We may change the Terms and Conditions to make improvements, without any
        prior notice.
      </li>
      <li>You have read and agree to all the above terms and conditions.</li>
    </ul>
  </div>
);

export const eligibilityPlasmaModalBody = (
  <div className="posterBox">
    <p className="headingPoster">
      Plasma Donor <br /> Eligibility Critieria
    </p>
    <div className="posterImgBox">
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/covid +ve.jpg"
          alt=""
        />
        <p>Tested Covid +ve in the last 120 days</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/pregnant.jpg"
          alt=""
        />
        <p>Woman must not have conceived a child</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/high BP.png"
          alt=""
        />
        <p>Should not have either High BP or Diabetes</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/old man.png"
          alt=""
        />
        <p>Should be between 18-60 yrs</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/plasma.png"
          alt=""
        />
        <p>Should not have receied plasma themselves</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/recovered.png"
          alt=""
        />
        <p>Should have NO active symptoms since last 14 days</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/vaccinated.png"
          alt=""
        />
        <p>Should not have had the COVID Vaccine</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityPlasmaImages/weightabove 55 kg.png"
          alt=""
        />
        <p>Should weigh atleast 50Kgs</p>
      </div>
    </div>
  </div>
);

export const eligibilityBloodModalBody = (
  <div className="posterBox">
    <p className="headingPoster">
      Blood Donor <br /> Eligibility Critieria
    </p>
    <div className="posterImgBox">
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/old man.png"
          alt=""
        />
        <p>Age between 18-60 years</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/weightabove 55 kg.png"
          alt=""
        />
        <p>Weight more than 50 kgs</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/fit.png"
          alt=""
        />
        <p> Should be fit and healthy</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/tattoo.png"
          alt=""
        />
        <p>NO Tattoo in last 6 months</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/surgery.png"
          alt=""
        />
        <p> NO Surgery in last 12 months</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/recovered.png"
          alt=""
        />
        <p>NO co-morbidities or any chronic diseases</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/vaccine.png"
          alt=""
        />
        <p> Taken Covid Vaccination (1st or 2nd) minimum 14 days before</p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/pregnant.png"
          alt=""
        />
        <p>
         No deliverey in last 1 year and stopped lactation
        </p>
      </div>
      <div className="posterImg">
        <img
          src="/eligibilityImages/eligibilityBloodImages/sexSymbol.png"
          alt=""
        />
        <p>NO Drug Addiction or unprotected intercourse</p>
      </div>
    </div>
  </div>
);
