import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "./eligibilityModal.css";

function getModalStyle() {
  const top = 35;
  const left = 35;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 270,
    height: 500,
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: "0 0.5rem 1rem rgba(0,0,0,0.2)",
    borderRadius: "0.3rem",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      height: "500px",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "500px",
      width: "80%",
    },
  },
}));

export default function EligibilityModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {props.body}
      <button id="OK" onClick={handleClose}>
        I am Eligible
      </button>
    </div>
  );

  return (
    <div style={{ display: "inline-block" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
