import React from "react";
import { Link } from "react-router-dom";

function TopSection() {
  return (
    <div className="grid ">


      {/*-------------------1---------------------*/}


      <div className="h-full text-center flex flex-col-reverse md:flex-row">

        <div className="md:w-1/3 h-full  flex flex-col py-8 px-4 justify-center items-center">
          <h1 className="sm:text-3xl text-2xl mb-4 font-bold py-3" style={{
            fontFamily: `'Poppins', sans-serif`,
            fontSize: "4em",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            एक दान
            </h1>
          <h1 className="sm:text-3xl text-2xl mb-4 font-bold py-3" style={{
            fontFamily: `'Poppins', sans-serif`,
            fontSize: "4em",
            backgroundImage: "linear-gradient(#FF0000, #4D0000)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>एक जान
            </h1>
          <p className=" text-xl mx-2 mt-8 mb-4 text-left md:text-left">
            Lend us your ears, for we invite donors who are willing to donate blood voluntarily. This will help us keep a list, for those who might have unfortunate needs. We, at Donors Army, aim to provide an open platform for all, be it donors or receivers.
            </p>
          <a href="/signup" className="text-white bg-gradient-to-t my-4 from-tertiary to-secondary py-3 px-6 shadow-md focus:outline-none  rounded-full">Register as Blood donor</a>
        </div>

        <div className="md:w-2/3 h-full" >
          <img src="/hompageImages/1.png" className="h-full w-full p-4" />
        </div>

      </div>


      {/*--------------------------2---------------------------*/}


      <div className="h-full text-center mt-2 flex flex-col-reverse md:flex-row-reverse">
       
        <div className="md:w-1/2 h-full  flex flex-col  px-4 justify-center items-center md:justify-evenly">
          <p className=" text-xl mx-2 mt-2 mb-4 text-left md:text-left">The pandemic has amplified the need for blood banks. Those in dire need are knocking help, only to be turned back. A 2019 study revealed that over 63 districts in India haven't got a single blood bank. 12,000 Indians die every day on account of limited blood donations. The figure is soaring higher, with the onset of the unprecedented crises.</p>
          <a href="/find-donor" className="text-white shadow-md bg-gradient-to-t my-4 from-tertiary to-secondary py-3 px-12 focus:outline-none  rounded-full">Find Donor</a>
        </div>

        <div className="md:w-1/2 h-full" >
          <img src="/hompageImages/6.png" className="h-full w-full p-4" />
        </div>


      </div>


      {/*-------------------------3-----------------------*/}


      <div className="h-full text-center lg:bg-primary flex flex-col-reverse mt-6 lg:pt-24 md:flex-row-reverse items-center">
        <div className="md:w-1/2 h-full  flex flex-col  px-4 justify-center lg:items-start items-center">
          <h1 className="mb-2 font-bold" style={{
            fontFamily: `'Poppins', sans-serif`,
            fontSize: "3em",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            Vision
         </h1>
          <p className=" text-xl mx-2 mb-4 lg:pr-48 lg:text-white text-left md:text-left">
            We as a team of inspired individuals perceive voluntary blood donations as indispensable. We bid to do our share by disseminating crucial information, bursting myths, and providing a commonplace of help to all.
         </p>
        </div>
       
        <div className="md:w-1/2 h-full lg:flex-row lg:flex lg:justify-end" >
          <img src="/hompageImages/4.png" className="lg:w-60 lg:h-60 w-64 h-64 p-4" />
        </div>

      </div>


      {/*-----------------------------4-------------------------*/}


      <div className="h-full text-center lg:bg-primary mt-6 lg:mt-0 lg:py-24 flex flex-col md:flex-row-reverse items-center">
        
        <div className="md:w-1/2 lg:pl-16 h-full lg:flex-row lg:flex lg:justify-start" >
          <img src="/hompageImages/5.png" className="lg:h-60 lg:w-60 w-full h-full p-4" />
        </div>
        
        <div className="md:w-1/3 h-full  flex flex-col  px-4 justify-center items-center lg:items-end">
          <h1 className="mb-2 font-bold" style={{
            fontFamily: `'Poppins', sans-serif`,
            fontSize: "3em",
            backgroundImage: "linear-gradient(#FF2731, #9C1F25)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>
            Mission
         </h1>
          <p className="text-xl mx-2  mb-4 lg:text-white  text-left md:text-right">
            We aim to bridge the gap between recovery and death. A transparent platform with real-time data and verified leads is the driving force behind our mission.
         </p>
        </div>

      </div>

    </div>
  );
}

export default TopSection;
